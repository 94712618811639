import { ChangeEvent, FC, useState } from "react";

import {
  ButtonColumnContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  TextDivider,
} from "../../../global.styles";
import Button from "../../UI/button/button.component";
import { SpacerVertical } from "../../UI/spacers/spacers.component";
import FormInput from "../../UI/form-input/form-input.component";
import { transformTimestampDateOnly } from "../../../util-functions";
import { CurrentUserType } from "../../../redux/user/user.types";
import {
  approveUserDriverDoc,
  rejectUserDriverDoc,
} from "../../../firebase/admin";

type ApproveDriverDocProps = {
  driver: CurrentUserType;
  docTitle: "PHBadge" | "PHDriversLicence" | "DVLALicence";
  onDocumentReviewed: () => void;
  onError: (error: string) => void;
};

const ApproveDriverPaperwork: FC<ApproveDriverDocProps> = ({
  driver,
  docTitle,
  onDocumentReviewed,
  onError,
}) => {
  const [expiryDate, setExpiryDate] = useState("");
  const [reasonOfDecline, setReasonOfDecline] = useState("");

  if (!driver || !driver.driversData || !driver.driversData[docTitle]) {
    return <RedHeader>Incomplete data for this document</RedHeader>;
  }

  const pendingUrl = driver.driversData[docTitle]!.pendingUrl;
  const downloadUrl = driver.driversData[docTitle]!.downloadUrl;
  const expiringUrl = driver.driversData[docTitle]!.expiringUrl;
  const currentExpiryDate = transformTimestampDateOnly(
    driver.driversData[docTitle]!.expires
  );

  //input change handlers
  const dateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setExpiryDate(event.target.value);
  };
  const reasonChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setReasonOfDecline(event.target.value);
  };

  //submithandlers
  const approveHandler = async () => {
    const expiryDateStamp = new Date(expiryDate).getTime();
    if (!expiryDateStamp || expiryDateStamp < new Date().getTime()) return;
    try {
      await approveUserDriverDoc(driver.id, docTitle, expiryDateStamp);
    } catch (error) {
      if (error instanceof Error) {
        onError(error.message);
      } else {
        onError("Error approving paperwork for the Driver");
      }
    }
    onDocumentReviewed();
  };
  const declineHandler = async () => {
    if (!reasonOfDecline || reasonOfDecline === "") return;
    try {
      await rejectUserDriverDoc(driver.id, docTitle, reasonOfDecline);
    } catch (error) {
      if (error instanceof Error) {
        onError(error.message);
      } else {
        onError("Error rejecting paperwork for the Driver");
      }
    }
    onDocumentReviewed();
  };

  return (
    <ButtonColumnContainer>
      <GreenP>
        {driver.name} - {driver.driversData.licensingAuthority}
      </GreenP>
      <SpacerVertical $paddingRem={1} />
      {expiringUrl && (
        <ClickableArea>
          <GreenHeader onClick={() => window.open(expiringUrl, "_blank")}>
            Expiring Document &rarr;
          </GreenHeader>
        </ClickableArea>
      )}
      {downloadUrl && (
        <ClickableArea>
          <GreenHeader onClick={() => window.open(downloadUrl, "_blank")}>
            Current Document &rarr;
          </GreenHeader>
        </ClickableArea>
      )}
      {pendingUrl && (
        <ClickableArea>
          <GreenHeader onClick={() => window.open(pendingUrl, "_blank")}>
            Pending Document &rarr;
          </GreenHeader>
        </ClickableArea>
      )}
      {pendingUrl ? (
        <>
          <FormInput
            label="Set Expiry Date"
            type="date"
            onChange={dateChangeHandler}
            required
          />
          <Button onClick={approveHandler}>Approve</Button>
          <TextDivider>
            <GreenP> OR </GreenP>
          </TextDivider>
        </>
      ) : currentExpiryDate ? (
        <GreenP>
          Expires: <strong>{currentExpiryDate}</strong>
        </GreenP>
      ) : null}
      <FormInput
        label="Reason of Decline"
        onChange={reasonChangeHandler}
        required
      />
      <Button buttonType="warning" onClick={declineHandler}>
        Decline
      </Button>
    </ButtonColumnContainer>
  );
};

export default ApproveDriverPaperwork;
