import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { IoDocumentsOutline, IoListSharp } from "react-icons/io5";
import { FaUsersGear } from "react-icons/fa6";
import Card from "../UI/card/card.component";
import InnerCard from "../UI/inner-card/inner-card.component";
import { RootState } from "../../redux/store";
import { AmberHeader } from "../../global.styles";
import { colors } from "../../style-variables";

const AdminPanel = () => {
  const navigate = useNavigate();

  //get list of drivers waitng for approval (max 50)
  const { userList } = useSelector((state: RootState) => state.userList);

  return (
    <Card>
      {userList && userList.length > 0 && (
        <InnerCard
          onClick={() => {
            navigate("approve-paperwork");
          }}
        >
          <IoDocumentsOutline size={32} color={colors.primary} />
          <div>
            <AmberHeader>New Paperwork For Approval</AmberHeader>
          </div>
        </InnerCard>
      )}
      <InnerCard
        onClick={() => {
          navigate("manage-users");
        }}
      >
        <FaUsersGear size={32} />
        <div>
          <h3>Manage Users</h3>
        </div>
      </InnerCard>
      <InnerCard
        onClick={() => {
          navigate("manage-bookings");
        }}
      >
        <IoListSharp size={32} />
        <div>
          <h3>Manage Bookings</h3>
        </div>
      </InnerCard>
    </Card>
  );
};

export default AdminPanel;
