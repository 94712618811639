import { ChangeEvent, FC, useState } from "react";

import { ButtonColumnContainer, GreenP, RedP } from "../../../global.styles";
import FormInput from "../../UI/form-input/form-input.component";
import Button from "../../UI/button/button.component";
import {
  PHVehicleType,
  VehicleType,
} from "../../../redux/vehicles/vehicles.types";
import Checkbox from "../../UI/checkbox/checkbox.component";
import { setMakeAndModel } from "../../../firebase/admin";

type MakeModelProps = {
  userId: string;
  selectedVehicle: PHVehicleType;
  onCancelSet: () => void;
  onError: (error: string) => void;
};

const SetMakeModelForm: FC<MakeModelProps> = ({
  userId,
  selectedVehicle,
  onCancelSet,
  onError,
}) => {
  const [make, setMake] = useState(selectedVehicle?.make);
  const [model, setModel] = useState(selectedVehicle?.model);
  const [color, setColor] = useState(selectedVehicle?.color);
  const [licencePlate, setLicencePlate] = useState(
    selectedVehicle?.licencePlate
  );
  const [vehicleTypes, setVehicleTypes] = useState<VehicleType[]>(
    selectedVehicle?.vehicleTypes || []
  );
  const [error, setError] = useState("");

  //input change handlers
  const makeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setMake(event.target.value);
  };
  const modelChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setModel(event.target.value);
  };
  const colorChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setColor(event.target.value);
  };
  const licencePlateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setLicencePlate(event.target.value);
  };
  const vehicleTypeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const updatedVehicleTypes = [
        ...vehicleTypes,
        event.target.value as VehicleType,
      ];
      setVehicleTypes(updatedVehicleTypes);
    } else {
      const index = vehicleTypes.indexOf(event.target.value as VehicleType);
      const updatedVehicleTypes = [...vehicleTypes];
      updatedVehicleTypes.splice(index, 1);
      setVehicleTypes(updatedVehicleTypes);
    }
  };

  //submit handler
  const submitHandler = async () => {
    if (
      make === "" ||
      make === undefined ||
      model === "" ||
      model === undefined ||
      color === "" ||
      color === undefined ||
      licencePlate === "" ||
      licencePlate === undefined ||
      vehicleTypes.length === 0
    ) {
      setError("Please fill out all fields!");
      return;
    }
    try {
      await setMakeAndModel(
        userId,
        selectedVehicle.numberPlate,
        make,
        model,
        color,
        licencePlate,
        vehicleTypes
      );
    } catch (error) {
      if (error instanceof Error) {
        onError(error.message);
      } else {
        onError("Error setting details for the vehicle");
      }
    }
    onCancelSet();
  };

  return (
    <ButtonColumnContainer>
      <FormInput
        label="Make"
        value={make}
        onChange={makeChangeHandler}
        required
      />
      <FormInput
        label="Model"
        value={model}
        onChange={modelChangeHandler}
        required
      />
      <FormInput
        label="Colour"
        value={color}
        onChange={colorChangeHandler}
        required
      />
      <FormInput
        label="Licence Number"
        value={licencePlate}
        onChange={licencePlateChangeHandler}
        required
      />
      <GreenP>Vehcile Types:</GreenP>
      <Checkbox
        label="Standard 4 seater"
        id="4Seater"
        name="4Seater"
        value="4Seater"
        checked={vehicleTypes.includes("4Seater")}
        onChange={vehicleTypeHandler}
      />
      <Checkbox
        label="Estate 4 seater"
        id="4SeaterEstate"
        name="4SeaterEstate"
        value="4SeaterEstate"
        checked={vehicleTypes.includes("4SeaterEstate")}
        onChange={vehicleTypeHandler}
      />
      <Checkbox
        label="6 seater"
        id="6Seater"
        name="6Seater"
        value="6Seater"
        checked={vehicleTypes.includes("6Seater")}
        onChange={vehicleTypeHandler}
      />
      <Checkbox
        label="Minibus"
        id="minibus"
        name="minibus"
        value="minibus"
        checked={vehicleTypes.includes("minibus")}
        onChange={vehicleTypeHandler}
      />
      <Checkbox
        label="Executive"
        id="executive"
        name="executive"
        value="executive"
        checked={vehicleTypes.includes("executive")}
        onChange={vehicleTypeHandler}
      />
      {error && <RedP>{error}</RedP>}
      <Button type="submit" onClick={submitHandler}>
        Submit
      </Button>
    </ButtonColumnContainer>
  );
};

export default SetMakeModelForm;
