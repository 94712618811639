import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  IoBookOutline,
  IoCarOutline,
  IoDocumentsOutline,
  IoSearchOutline,
  IoSettingsOutline,
  IoWarning,
} from "react-icons/io5";
import { TbClockPlay, TbClockQuestion } from "react-icons/tb";
import Card from "../../UI/card/card.component";
import InnerCard from "../../UI/inner-card/inner-card.component";
import {
  AmberHeader,
  GreenHeader,
  RedHeader,
  VerticalArea,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import Spinner from "../../UI/spinner/spinner.component";
import {
  DriverNumber,
  DriverNumberContainer,
} from "../application/application.styles";
import { colors } from "../../../style-variables";

const requiresAttention = <IoWarning color={colors.primary} size={32} />;

const DriverPanel = () => {
  const navigate = useNavigate();

  const { currentUser, userLoading, userError } = useSelector(
    (state: RootState) => state.user
  );
  const { vehiclesLoading, vehiclesError, vehicles } = useSelector(
    (state: RootState) => state.vehicles
  );
  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);
  const { jobOffers, jobsOffersLoading, jobsOffersError } = useSelector(
    (state: RootState) => state.jobOffers
  );

  const [isSelectedCarApproved, setIsSelectedCarApproved] = useState(false);

  useEffect(() => {
    if (!!vehicles) {
      const selectedVehicle = vehicles.find((vehicle) => vehicle.selected);
      if (selectedVehicle?.approved) {
        setIsSelectedCarApproved(true);
      }
    }
  }, [vehicles]);

  return (
    <Card>
      {approvedDriver &&
      !approvedDriver.pausedRequests &&
      currentUser!.readyToDrive &&
      currentUser!.driverNumber ? (
        <>
          <GreenHeader>Status: ACTIVE</GreenHeader>
          <h3>
            You are all set and ready to take bookings, <br />
            Your Driver number is:
          </h3>
          <DriverNumberContainer>
            <DriverNumber>{currentUser!.driverNumber}</DriverNumber>
          </DriverNumberContainer>
          <h3>Give it to your customers, so they can book you directly!</h3>
        </>
      ) : approvedDriver &&
        approvedDriver.pausedRequests &&
        currentUser!.readyToDrive &&
        currentUser!.driverNumber ? (
        <>
          <AmberHeader>Status: NEW REQUESTS STOPPED</AmberHeader>
          <h3>You can change this status via Settings section below...</h3>
          <br />
        </>
      ) : (
        <>
          <RedHeader>Status: NOT ACTIVE</RedHeader>
        </>
      )}
      {(userLoading ||
        vehiclesLoading ||
        approvedDriverLoading ||
        jobsOffersLoading) && <Spinner />}
      {userError && <RedHeader>{userError}</RedHeader>}
      {approvedDriverError && <RedHeader>{approvedDriverError}</RedHeader>}
      {jobsOffersError && <RedHeader>{jobsOffersError}</RedHeader>}
      {vehiclesError && <RedHeader>{vehiclesError}</RedHeader>}
      <InnerCard
        onClick={() => {
          navigate("/drivers/application");
        }}
      >
        <IoDocumentsOutline size={32} />
        <VerticalArea>
          {(!currentUser!.approvedDriver || !isSelectedCarApproved) &&
            requiresAttention}
          <h3>My Paperwork</h3>
        </VerticalArea>
      </InnerCard>
      <InnerCard
        onClick={() => {
          navigate("/drivers/vehicle-list");
        }}
      >
        <IoCarOutline size={32} />
        <VerticalArea>
          {!isSelectedCarApproved && requiresAttention}
          <h3>My Cars</h3>
        </VerticalArea>
      </InnerCard>
      <InnerCard
        onClick={() => {
          navigate("/drivers/accepted-jobs");
        }}
      >
        <TbClockPlay size={32} />
        <div>
          <h3>Accepted Jobs</h3>
        </div>
      </InnerCard>
      <InnerCard
        onClick={() => {
          navigate("/drivers/my-job-offers");
        }}
      >
        {jobOffers && jobOffers.length > 0 ? (
          <AmberHeader>{jobOffers.length.toString()}</AmberHeader>
        ) : (
          <TbClockQuestion size={32} />
        )}
        <div>
          {jobOffers && jobOffers.length > 0 ? (
            <AmberHeader>New Job Offer(s)</AmberHeader>
          ) : (
            <h3>My Job Offers</h3>
          )}
        </div>
      </InnerCard>
      <InnerCard
        onClick={() => {
          navigate("/drivers/public-job-list");
        }}
      >
        <IoSearchOutline size={32} />
        <div>
          <h3>Public Jobs / Search Jobs</h3>
        </div>
      </InnerCard>
      <InnerCard
        onClick={() => {
          navigate("/drivers/settings");
        }}
      >
        <IoSettingsOutline
          size={32}
          color={
            approvedDriver && approvedDriver.pausedRequests
              ? colors.primary
              : undefined
          }
        />
        <div>
          {approvedDriver && approvedDriver.pausedRequests ? (
            <AmberHeader>Settings</AmberHeader>
          ) : (
            <h3>Settings</h3>
          )}
        </div>
      </InnerCard>
      <InnerCard
        onClick={() => {
          navigate("/drivers/jobs-history");
        }}
      >
        <IoBookOutline size={32} />
        <div>
          <h3>History / Completed Jobs</h3>
        </div>
      </InnerCard>
    </Card>
  );
};

export default DriverPanel;
