import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import CookieConsent from "react-cookie-consent";

import Home from "./routes/home/home.component";
import Navigation from "./routes/navigation/navigation.component";
import Terms from "./routes/terms/terms.component";
import NotFound from "./routes/not-found/not-found.component";
import Contact from "./routes/contact/contact.component";
import Profile from "./routes/profile/profile.component";
import Authentication from "./routes/authentication/authentication.component";
import {
  checkUserSession,
  errorUserRealTime,
  updateUserRealTime,
} from "./redux/user/user.slice";
import { auth, firestore } from "./firebase/config";
import Bookings from "./routes/bookings/bookings-component";
import Drivers from "./routes/drivers/drivers.component";
import LoggedInRoute from "./components/navigation/protected-routes/logged-in-route.component";
import { RootState } from "./redux/store";
import {
  clearVehicles,
  errorVehiclesRealTime,
  updateVehiclesRealTime,
} from "./redux/vehicles/vehicles.slice";
import AdminRoute from "./components/navigation/protected-routes/admin-route.component";
import Admin from "./routes/admin/admin-component";
import { ApprovedDriverType, CurrentUserType } from "./redux/user/user.types";
import { PHVehicleType } from "./redux/vehicles/vehicles.types";
import PrivacyPolicy from "./routes/privacy-policy/privacy-policy.component";
import { colors } from "./style-variables";
import { BookingType } from "./redux/bookings/bookings.types";
import {
  clearApprovedDriver,
  errorApprovedDriverRealTime,
  updateApprovedDriverRealTime,
} from "./redux/approvedDRiver/approved-driver.slice";
import {
  clearJobOffers,
  errorJobOffersRealTime,
  updateJobOffersRealTime,
} from "./redux/jobs/job-offers.slice";
import {
  clearUserList,
  getDriversToApproveFailed,
  getDriversToApproveSuccess,
} from "./redux/admin/user-list/userList.slice";
import Accounts from "./components/accounts/accounts.component";

function App() {
  const dispatch = useDispatch();

  const [user] = useAuthState(auth);
  //get User from redux store
  const { currentUser } = useSelector((state: RootState) => state.user);
  //if User is an approvedDriver
  const { approvedDriver } = useSelector(
    (state: RootState) => state.approvedDriver
  );

  useEffect(() => {
    //check if user is logged in and is in Firestore DB
    //if not - create document
    //get userData from DB to redux
    dispatch(checkUserSession());
  }, [dispatch, user]);

  const userId = currentUser?.id;
  //set onSnapshot (real time updates connection) for currentUser
  useEffect(() => {
    let unsubscribe: (() => void) | null = null; // Initialize as null
    if (userId) {
      unsubscribe = onSnapshot(
        doc(firestore, "users", userId),
        { includeMetadataChanges: true },
        (userDoc) => {
          //wait for serverTimestamps
          if (!userDoc.metadata.hasPendingWrites) {
            const updatedUser = {
              id: userDoc.id,
              ...userDoc.data(),
              createdAt: userDoc.data()!.createdAt.toMillis(),
              lastUpdate: userDoc.data()!.lastUpdate.toMillis(),
            };
            dispatch(updateUserRealTime(updatedUser as CurrentUserType));
          }
        },
        (error) => {
          if (error instanceof Error) {
            dispatch(errorUserRealTime(error.message));
          } else {
            dispatch(errorUserRealTime("Error fetching current User"));
          }
        }
      );
    }
    // Cleanup function is always defined
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, userId]);

  // ---=== Driver connections ===---
  //if Driver has any registered vehicles then onSnapshot vehicles from DB to redux
  useEffect(() => {
    let unsubscribe: (() => void) | null = null; // Initialize as null
    if (userId) {
      //dispatch(getUserVehiclesStart(userId));
      const vehiclesRef = collection(firestore, "users", userId, "PHVehicles");
      unsubscribe = onSnapshot(
        vehiclesRef,
        { includeMetadataChanges: true },
        (snapshot) => {
          if (!snapshot.metadata.hasPendingWrites) {
            const updatedVehicles = snapshot.docs.map((docSnapshot) => {
              //transforming timestamps from non-serializable to milliseconds number
              return {
                ...docSnapshot.data(),
                createdAt: docSnapshot.data()!.createdAt.toMillis(),
                lastUpdate: docSnapshot.data()!.lastUpdate.toMillis(),
              };
            });
            dispatch(
              updateVehiclesRealTime(updatedVehicles as PHVehicleType[])
            );
          }
        },
        (error) => {
          if (error instanceof Error) {
            dispatch(errorVehiclesRealTime(error.message));
          } else {
            dispatch(errorVehiclesRealTime("Error fetching vehicles"));
          }
        }
      );
    } else {
      dispatch(clearVehicles());
    }
    // Cleanup function is always defined
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, userId]);
  //if Driver is readyToDrive fetch their data from approvedDrivers collection
  useEffect(() => {
    let unsubscribe: (() => void) | null = null; // Initialize as null
    if (userId) {
      unsubscribe = onSnapshot(
        doc(firestore, "approvedDrivers", userId),
        { includeMetadataChanges: true },
        (approvedDriverDoc) => {
          //wait for serverTimestamps
          if (
            !approvedDriverDoc.metadata.hasPendingWrites &&
            approvedDriverDoc.exists()
          ) {
            const updatedApprovedDriver = {
              id: approvedDriverDoc.id,
              ...approvedDriverDoc.data(),
            };
            dispatch(
              updateApprovedDriverRealTime(
                updatedApprovedDriver as ApprovedDriverType
              )
            );
          } else {
            // Document doesn't exist, clear the approved driver from Redux
            dispatch(clearApprovedDriver());
          }
        },
        (error) => {
          if (error instanceof Error) {
            dispatch(errorApprovedDriverRealTime(error.message));
            console.log(error.message);
          } else {
            dispatch(
              errorApprovedDriverRealTime("Error fetching approved Driver")
            );
          }
        }
      );
    } else {
      dispatch(clearApprovedDriver());
    }
    // Cleanup function is always defined
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [dispatch, userId]);
  //personal job requests for the Driver
  useEffect(() => {
    let unsubscribe: (() => void) | null = null; // Initialize as null
    if (userId && approvedDriver) {
      const jobOffersQuery = query(
        collection(firestore, "bookings"),
        where("driverNumber", "==", approvedDriver.driverNumber),
        where("accepted", "==", false),
        where("notCovered", "==", false),
        where("completed", "==", false),
        orderBy("pickupTimestamp", "asc"),
        limit(25)
      );
      unsubscribe = onSnapshot(
        jobOffersQuery,
        { includeMetadataChanges: true },
        (snapshot) => {
          if (!snapshot.metadata.hasPendingWrites) {
            const updatedJobOffers = snapshot.docs.map((docSnapshot) => {
              //transforming timestamps from non-serializable to milliseconds number
              const data = docSnapshot.data();
              return {
                id: docSnapshot.id,
                ...data,
                createdAt: data.createdAt.toMillis(),
                //convert pickupGeoPoint into serializable object for Redux store
                pickupGeoPoint: {
                  latitude: data.pickupGeoPoint.latitude,
                  longitude: data.pickupGeoPoint.longitude,
                },
              };
            });
            dispatch(
              updateJobOffersRealTime(updatedJobOffers as BookingType[])
            );
          }
        },
        (error) => {
          if (error instanceof Error) {
            dispatch(errorJobOffersRealTime(error.message));
          } else {
            dispatch(errorJobOffersRealTime("Error fetching job offers"));
          }
        }
      );
    } else {
      dispatch(clearJobOffers());
    }
    // Cleanup function is always defined
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [approvedDriver, dispatch, userId]);

  // ---=== Admin connections ===---
  //fetch up to 50 Drivers that are waiting for paperwork approvalfrom DB to redux
  useEffect(() => {
    // dispatch(getDriversToApproveStart());
    let unsubscribe: (() => void) | null = null; // Initialize as null
    if (currentUser && currentUser.isBoss) {
      const userColRef = collection(firestore, "users");
      const userQuery = query(
        userColRef,
        where("waitingDriverApproval", "==", true),
        limit(50)
      );
      unsubscribe = onSnapshot(
        userQuery,
        { includeMetadataChanges: true },
        (snapshot) => {
          if (!snapshot.metadata.hasPendingWrites) {
            const driversForApproval = snapshot.docs.map((docSnapshot) => {
              //transforming timestamps from non-serializable to milliseconds number
              return {
                ...docSnapshot.data(),
                id: docSnapshot.id,
                createdAt: docSnapshot.data()!.createdAt.toMillis(),
                lastUpdate: docSnapshot.data()!.lastUpdate.toMillis(),
              };
            });
            dispatch(
              getDriversToApproveSuccess(
                driversForApproval as CurrentUserType[]
              )
            );
          }
        },
        (error) => {
          if (error instanceof Error) {
            dispatch(getDriversToApproveFailed(error.message));
          } else {
            dispatch(
              getDriversToApproveFailed(
                "Error fetching Drivers for paperwork approval"
              )
            );
          }
        }
      );
    } else {
      dispatch(clearUserList());
    }
    // Cleanup function is always defined
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [currentUser, dispatch]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Authentication />} />
          <Route
            path="profile/*"
            element={
              <LoggedInRoute>
                <Profile />
              </LoggedInRoute>
            }
          />
          <Route
            path="bookings/*"
            element={
              <LoggedInRoute>
                <Bookings />
              </LoggedInRoute>
            }
          />
          <Route path="drivers/*" element={<Drivers />} />
          <Route path="accounts/*" element={<Accounts />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="boss/*"
            element={
              <AdminRoute>
                <Admin />
              </AdminRoute>
            }
          />
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
      </Routes>
      <CookieConsent
        // debug={true}
        location="bottom"
        buttonText="I understand"
        cookieName="myAwesomeCookieName2"
        style={{
          background: colors.background,
          border: `0.7rem solid ${colors.dark}`,
        }}
        contentStyle={{
          color: colors.light,
          fontSize: "1.4rem",
        }}
        buttonStyle={{
          color: colors.lightTextHigh,
          backgroundColor: colors.primary,
          fontSize: "1.4rem",
          borderRadius: "0.7rem",
        }}
        expires={150}
      >
        This website uses cookies to enhance Your experience, and to actually
        make it work.
        <br />
        Please read our{" "}
        <Link to="/privacy-policy">
          <strong>Privacy Policy</strong>
        </Link>{" "}
        to learn more about it.
      </CookieConsent>
    </>
  );
}

export default App;
