import { useNavigate } from "react-router-dom";

import { BackButtonContainer, GreenHeader } from "../../global.styles";
import Button from "../UI/button/button.component";
import Card from "../UI/card/card.component";

const Accounts = () => {
  const navigate = useNavigate();

  return (
    <Card>
      <GreenHeader>
        Welcome to Rufus Taxi Services Business Accounts
      </GreenHeader>
      <p>
        We’re excited to introduce an exclusive feature designed for businesses!
        Soon, you’ll be able to create a dedicated account with us, simplifying
        your taxi booking process and streamlining travel for your team.
      </p>
      <br />
      <p>
        This feature is currently under construction and will be launching soon.
        Thank you for your patience as we work to bring you this exciting new
        service. Stay tuned!
      </p>
      <BackButtonContainer>
        <Button buttonType="green" onClick={() => navigate("/")}>
          Ok
        </Button>
      </BackButtonContainer>
    </Card>
  );
};

export default Accounts;
