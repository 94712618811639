import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  collection,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";

import { RootState } from "../../redux/store";
import { firestore } from "../../firebase/config";
import {
  BackButtonContainer,
  GreenHeader,
  GreenP,
  RedHeader,
} from "../../global.styles";
import Card from "../UI/card/card.component";
import Spinner from "../UI/spinner/spinner.component";
import InnerCard from "../UI/inner-card/inner-card.component";
import { BookingType } from "../../redux/bookings/bookings.types";
import BookingStatus from "./booking-status/booking-status.component";

const BookingsHistory = () => {
  const navigate = useNavigate();

  const isFirstMount = useRef(true); // Track initial mount to prevent errors on double mount

  const { currentUser } = useSelector((state: RootState) => state.user);

  const [bookingsLoading, setBookingsLoading] = useState(false);
  const [bookingsError, setBookingsError] = useState("");
  const [bookings, setBookings] = useState<BookingType[]>([]);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(); // To track the last document for pagination
  const [paginationOn, setPaginationOn] = useState(false);

  const fetchBookings = useCallback(
    async (startAfterDoc: DocumentData | null = null) => {
      setBookingsLoading(true);
      setBookingsError("");
      try {
        let bookingsQuery = query(
          collection(firestore, "bookings"),
          where("userId", "==", currentUser?.id),
          where("completed", "==", true),
          orderBy("pickupTimestamp", "desc"),
          limit(10)
        );
        if (startAfterDoc) {
          bookingsQuery = query(bookingsQuery, startAfter(startAfterDoc));
        }
        const bookingsSnapshot = await getDocs(bookingsQuery);
        const newBookings = bookingsSnapshot.docs.map((docSnapshot) => {
          const data = docSnapshot.data();
          return {
            ...data,
            //transforming timestamps from non-serializable to milliseconds number
            createdAt: data.createdAt ? data.createdAt.toMillis() : null, // Check if createdAt exists
            acceptedAt: data.acceptedAt ? data.acceptedAt.toMillis() : null, // Check if acceptedAt exists
            pickedUpAt: data.pickedUpAt ? data.pickedUpAt.toMillis() : null, // Check if pickedUpAt exists
            //convert pickupGeoPoint into serializable object for Redux store
            pickupGeoPoint: {
              latitude: docSnapshot.data()!.pickupGeoPoint.latitude,
              longitude: docSnapshot.data()!.pickupGeoPoint.longitude,
            },
            id: docSnapshot.id,
          } as BookingType;
        });
        if (bookingsSnapshot.docs.length > 0) {
          setBookings((prevBookings) =>
            prevBookings ? [...prevBookings, ...newBookings] : newBookings
          );
          // Update lastVisible for pagination
          setLastVisible(
            bookingsSnapshot.docs[bookingsSnapshot.docs.length - 1]
          );
        }
        if (bookingsSnapshot.docs.length > 9) {
          setPaginationOn(true);
        } else {
          setPaginationOn(false);
        }
      } catch (error) {
        if (error instanceof Error) {
          setBookingsError(error.message);
        } else {
          setBookingsError("Error fetching bookings");
        }
      } finally {
        setBookingsLoading(false);
      }
    },
    [currentUser?.id]
  );

  //initial fetch
  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false; // Set to false after the first mount
      fetchBookings();
    }
  }, [fetchBookings]);

  // Infinite scroll logic
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastBookingRef = useRef<HTMLDivElement | null>(null); // Reference to the last booking element
  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (paginationOn && entries[0].isIntersecting && lastVisible) {
          fetchBookings(lastVisible);
        }
      },
      { rootMargin: "200px" }
    );
    observerRef.current = observer; // Assign the observer to the ref
    // Observe the target element only if it exists
    if (lastBookingRef.current) {
      observer.observe(lastBookingRef.current);
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [fetchBookings, lastVisible, paginationOn]);

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/">
          <h3>Go Back</h3>
        </Link>
      </BackButtonContainer>
      <GreenHeader>Trips History</GreenHeader>
      {bookingsError && <RedHeader>{bookingsError}</RedHeader>}
      {bookings.length === 0 && !bookingsError && (
        <GreenP>There are no trips in Your trips hisory yet...</GreenP>
      )}
      {!bookingsError &&
        bookings.length > 0 &&
        bookings.map((booking, index) => {
          //server stores dates in UTC, it doesn't care about winter/summer time
          //if you use server timestamp to interract with client timestamps convert it like this:
          // const serverPickupTime = new Date(booking.pickupTimestamp!);
          // const clientPickupTimestamp = serverPickupTime.setTime(
          //   serverPickupTime.getTime() +
          //     serverPickupTime.getTimezoneOffset() * 60 * 1000
          // );
          return (
            <div
              key={booking.id}
              ref={index === bookings.length - 1 ? lastBookingRef : null} // Assign ref conditionally for observer to fetch more bookings
            >
              <InnerCard
                onClick={() => {
                  navigate(`/bookings/booking-details/${booking.id}`);
                }}
              >
                <div>
                  <p>{booking.date}</p>
                  <p>
                    <strong>{booking.time}</strong>
                  </p>
                </div>
                <BookingStatus booking={booking} />
              </InnerCard>
            </div>
          );
        })}
      {bookingsLoading && <Spinner />}
    </Card>
  );
};

export default BookingsHistory;
