import { ChangeEvent, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaDog, FaExclamationTriangle } from "react-icons/fa";

import {
  AmberHeader,
  BackButtonContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  SpreadButtonContainer,
  TextDivider,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import { isBookingType } from "../../../redux/bookings/bookings.types";
import Button from "../../UI/button/button.component";
import Addresses from "../../bookings/addresses.component";
import { defineVehicleType } from "../../../util-functions";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import { acceptJobRequest, cancelJobRequest } from "../../../firebase/user";
import { colors } from "../../../style-variables";
import FormInput from "../../UI/form-input/form-input.component";

const JobOfferDetails = () => {
  const navigate = useNavigate();

  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const { jobsOffersLoading, jobsOffersError, jobOffers } = useSelector(
    (state: RootState) => state.jobOffers
  );

  const [acceptJobLoading, setAcceptJobLoading] = useState(false);
  const [acceptJobError, setAcceptJobError] = useState("");
  const [jobAccepted, setJobAccepted] = useState(false);
  const [jobCancelled, setJobCancelled] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [reasonOfReject, setReasonOfReject] = useState("");

  //get state that was passed to this route
  const { state } = useLocation();

  if (jobCancelled) {
    return (
      <Card>
        <GreenHeader>Job rejected</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => navigate("/drivers/my-job-offers")}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  if (jobAccepted) {
    return (
      <Card>
        <GreenHeader>Job successfully accepted!</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => navigate("/drivers/accepted-jobs")}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  if (!state || !approvedDriver || !jobOffers) {
    return (
      <Card>
        <RedHeader>Wrong Parameters...</RedHeader>
        <BackButtonContainer>
          <Button onClick={() => navigate("/drivers/driver-panel")}>
            Try Again
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  const jobId = state.jobId;
  const job = jobOffers.find((job) => job.id === jobId);

  if (!jobAccepted && !isBookingType(job)) {
    return (
      <Card>
        <GreenHeader>This job is no loger available...</GreenHeader>
        <BackButtonContainer>
          <Button onClick={() => navigate("/drivers/driver-panel")}>Ok</Button>
        </BackButtonContainer>
      </Card>
    );
  }

  const reasonChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setReasonOfReject(event.target.value);

  const rejectActionHandler = async () => {
    if (!reasonOfReject || reasonOfReject === "") {
      setAcceptJobError(
        "Please share your reason for not accepting this booking to help us improve."
      );
      return;
    }
    if (jobId) {
      setAcceptJobLoading(true);
      try {
        const data = await cancelJobRequest(jobId, reasonOfReject);
        if (data && data.message && data.message === "Job cancelled!") {
          setJobCancelled(true);
        }
      } catch (error) {
        if (error instanceof Error) {
          setAcceptJobError(error.message);
        } else {
          setAcceptJobError(
            "Problem cancelling this job... Please try again. If problem still exists, please let us know"
          );
        }
      }
      setAcceptJobLoading(false);
    } else {
      navigate("/drivers/my-job-offers");
    }
  };

  const acceptJobHandler = () => {
    if (jobId) {
      setAcceptJobLoading(true);
      acceptJobRequest(jobId)
        .then((accepted) => {
          setJobAccepted(accepted);
          setAcceptJobLoading(false);
        })
        .catch((error) => {
          if (error instanceof Error) {
            setAcceptJobError(error.message);
          } else {
            setAcceptJobError(
              "Problem accepting this job... Please make sure all parameters match. If problem still exists, please let us know"
            );
          }
          setAcceptJobLoading(false);
        });
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  return (
    <Card>
      {(approvedDriverLoading || acceptJobLoading || jobsOffersLoading) && (
        <Spinner />
      )}
      {approvedDriverError && !approvedDriverLoading && (
        <RedHeader>{approvedDriverError}</RedHeader>
      )}
      {jobsOffersError && !jobsOffersLoading && (
        <RedHeader>{jobsOffersError}</RedHeader>
      )}
      {!acceptJobLoading && !!job && (
        <>
          <BackButtonContainer>
            <ClickableArea onClick={() => navigate(-1)}>
              <h3>Go Back</h3>
            </ClickableArea>
          </BackButtonContainer>
          <GreenHeader>
            Personal request for
            <br />
            {job.date}
            <br />
            at {job.time}
          </GreenHeader>
          <TextDivider>
            <GreenP> ADDRESSES </GreenP>
          </TextDivider>
          <Addresses booking={job} />
          <TextDivider>
            <GreenP> DETAILS </GreenP>
          </TextDivider>
          <GreenP>Passenger Name</GreenP>
          <p>{job.passengerName}</p>
          <br />
          <GreenP>Passenger Phone Number</GreenP>
          <p>{job.passengerPhone}</p>
          <br />
          <GreenP>Vehicle Type:</GreenP>
          <p>{defineVehicleType(job.vehicleType)}</p>
          {job.hasPet && (
            <>
              <br />
              <GreenP>
                <FaDog /> Booking includes pet.
              </GreenP>
            </>
          )}
          {job.driverNote && (
            <>
              <br />
              <GreenP>Note to the Driver</GreenP>
              <p>{job.driverNote}</p>
            </>
          )}
          <TextDivider>
            <GreenP> PRICE </GreenP>
          </TextDivider>
          <GreenHeader> £{job.quotedPrice}*</GreenHeader>
          <GreenP>
            * Amount to pay to the Driver. Any parking and/or toll fees and/or
            waitng time charges to be added. Please see{" "}
            <Link to="/terms">Terms and Conditions</Link>
          </GreenP>
          <TextDivider>
            <GreenP> ACTIONS </GreenP>
          </TextDivider>
          {acceptJobError && !acceptJobLoading && (
            <RedHeader>{acceptJobError}</RedHeader>
          )}
          {!showRejectForm ? (
            <>
              <FaExclamationTriangle size={24} color={colors.primary} />
              <GreenP>
                <b>Important Reminder:</b>
              </GreenP>
              <GreenP>
                Before accepting this job, please check your other bookings to
                ensure there are no scheduling conflicts. Let’s keep all jobs on
                time! Thank you!
              </GreenP>
              <SpreadButtonContainer>
                <Button
                  buttonType="inverted"
                  onClick={() => setShowRejectForm(true)}
                >
                  Reject
                </Button>
                <Button onClick={acceptJobHandler}>Accept The Job</Button>
              </SpreadButtonContainer>
            </>
          ) : (
            <>
              <AmberHeader>Reject this job?</AmberHeader>
              <FormInput
                label="Please type the reason..."
                onChange={reasonChangeHandler}
                id="reasonOfReject"
                name="reasonOfReject"
                type="text"
                value={reasonOfReject}
                required
              />
              <SpreadButtonContainer>
                <Button
                  buttonType="inverted"
                  onClick={() => {
                    setShowRejectForm(false);
                    setAcceptJobError("");
                  }}
                >
                  Go Back
                </Button>
                <Button onClick={rejectActionHandler}>Reject</Button>
              </SpreadButtonContainer>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default JobOfferDetails;
