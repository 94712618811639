import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  collection,
  DocumentData,
  getDocs,
  limit,
  query,
  startAfter,
  where,
} from "firebase/firestore";

import Card from "../UI/card/card.component";
import { RootState } from "../../redux/store";
import Spinner from "../UI/spinner/spinner.component";
import {
  BackButtonContainer,
  GreenHeader,
  GreenP,
  PushToLeftColumn,
  PushToRightColumn,
  RedHeader,
} from "../../global.styles";
import Button from "../UI/button/button.component";
import FormInput from "../UI/form-input/form-input.component";
import RadioButton from "../UI/radio-buttons/radio-button";
import { defineAdminUserSearchField } from "../../util-functions";
import { firestore } from "../../firebase/config";
import InnerCard from "../UI/inner-card/inner-card.component";
import { CurrentUserType } from "../../redux/user/user.types";

const SearchUsers = () => {
  const navigate = useNavigate();

  const { userLoading, userError } = useSelector(
    (state: RootState) => state.user
  );

  const [searchError, setSearchError] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchField, setSearchField] = useState("email");
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState<CurrentUserType[]>([]);
  const [searchComplete, setSearchComplete] = useState(false);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(); // To track the last document for pagination
  const [paginationOn, setPaginationOn] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);

  //search text sanitizing function
  const sanitizeSearchText = useCallback(
    (searchText: string) => {
      let sanitizedText = searchText.trim();
      if (searchField !== "name") {
        sanitizedText = sanitizedText.replaceAll(" ", "");
        sanitizedText = sanitizedText.toLowerCase();
      }
      return sanitizedText;
    },
    [searchField]
  );

  //search function
  const searchUsers = useCallback(
    async (startAfterDoc: DocumentData | null = null) => {
      if (
        typeof searchField === "string" &&
        typeof searchText === "string" &&
        searchField.length > 0 &&
        searchText.length > 0
      ) {
        if (!!startAfterDoc) {
          setNextPageLoading(true);
        } else {
          setSearchLoading(true);
        }
        setSearchError("");
        try {
          let usersQuery = query(
            collection(firestore, "users"),
            where(searchField, "==", sanitizeSearchText(searchText)),
            limit(10)
          );
          if (startAfterDoc) {
            usersQuery = query(usersQuery, startAfter(startAfterDoc));
          }
          const usersSnapshot = await getDocs(usersQuery);
          const newUsers = usersSnapshot.docs.map((docSnapshot) => {
            //transforming timestamps from non-serializable to milliseconds number
            return {
              ...docSnapshot.data(),
              id: docSnapshot.id,
              createdAt: docSnapshot.data()!.createdAt.toMillis(),
              lastUpdate: docSnapshot.data()!.lastUpdate.toMillis(),
            } as CurrentUserType;
          });
          if (usersSnapshot.docs.length > 0) {
            setSearchResult((prevUsers) =>
              prevUsers ? [...prevUsers, ...newUsers] : newUsers
            );
            // Update lastVisible for pagination
            setLastVisible(usersSnapshot.docs[usersSnapshot.docs.length - 1]);
          }
          if (usersSnapshot.docs.length > 9) {
            setPaginationOn(true);
          } else {
            setPaginationOn(false);
          }
        } catch (error) {
          if (error instanceof Error) {
            setSearchError(error.message);
          } else {
            setSearchError("Error fetching users");
          }
        } finally {
          setSearchLoading(false);
          setNextPageLoading(false);
        }
      } else {
        setSearchError("Wrong search parameters");
      }
    },
    [sanitizeSearchText, searchField, searchText]
  );

  // Infinite scroll logic
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastUserRef = useRef<HTMLDivElement | null>(null); // Reference to the last user element
  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          searchComplete &&
          paginationOn &&
          entries[0].isIntersecting &&
          lastVisible
        ) {
          searchUsers(lastVisible);
        }
      },
      { rootMargin: "200px" }
    );
    observerRef.current = observer; // Assign the observer to the ref
    // Observe the target element only if it exists
    if (lastUserRef.current) {
      observer.observe(lastUserRef.current);
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [lastVisible, paginationOn, searchComplete, searchUsers]);

  //form change handlers
  const searchFieldChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchField(event.target.value);
  };
  const searchTextChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const submitSearchHandler = () => {
    if (!searchComplete) {
      searchUsers();
      setSearchComplete(true);
    }
  };

  if (userLoading || searchLoading) return <Spinner />;

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/boss">
          <h3>&larr; Admin Menu</h3>
        </Link>
      </BackButtonContainer>
      {userError && <RedHeader>{userError}</RedHeader>}
      {!searchComplete && (
        <>
          <GreenHeader>Search for Users</GreenHeader>
          {searchError && <RedHeader>{searchError}</RedHeader>}
          <form onSubmit={submitSearchHandler}>
            <GreenP>Select how you want to search:</GreenP>
            <RadioButton
              label="Email"
              id="email"
              name="searchField"
              value="email"
              defaultChecked
              onChange={searchFieldChangeHandler}
            />
            <RadioButton
              label="Phone Number"
              id="phoneNumber"
              name="searchField"
              value="phoneNumber"
              onChange={searchFieldChangeHandler}
            />
            <RadioButton
              label="Driver Number"
              id="driverNumber"
              name="searchField"
              value="driverNumber"
              onChange={searchFieldChangeHandler}
            />
            <RadioButton
              label="Name (case-sensitive)"
              id="name"
              name="searchField"
              value="name"
              onChange={searchFieldChangeHandler}
            />
            {searchField && (
              <>
                <FormInput
                  label={`Please enter ${defineAdminUserSearchField(
                    searchField
                  )}`}
                  id="search-text"
                  type="text"
                  autoCapitalize={searchField === "name" ? "words" : "null"}
                  onChange={searchTextChangeHandler}
                  value={searchText}
                  required
                />
              </>
            )}
            {searchError && <RedHeader>{searchError}</RedHeader>}
            <BackButtonContainer>
              <Button type="submit">Search Users</Button>
            </BackButtonContainer>
          </form>
        </>
      )}

      <>
        {searchComplete && searchResult.length === 0 && !searchError ? (
          <GreenHeader>
            No results for {defineAdminUserSearchField(searchField)}{" "}
            {sanitizeSearchText(searchText)} ...
          </GreenHeader>
        ) : searchResult.length > 0 && !searchError ? (
          <>
            <GreenHeader>
              Search Results for {defineAdminUserSearchField(searchField)}{" "}
              {sanitizeSearchText(searchText)}:
            </GreenHeader>
            {searchResult.map((user, index) => {
              return (
                <div
                  key={user.id}
                  ref={index === searchResult.length - 1 ? lastUserRef : null} // Assign ref conditionally for observer to fetch more users
                >
                  <InnerCard
                    onClick={() => navigate(`/boss/user-details/${user.id}`)}
                  >
                    <PushToLeftColumn>
                      <h3>{user.name}</h3>
                      <p>{user.email}</p>
                      <p>{user.phoneNumber}</p>
                    </PushToLeftColumn>
                    <PushToRightColumn>
                      <h3>Driver:</h3>
                      <p>
                        {user.driverNumber
                          ? user.driverNumber
                          : "No Driver Number"}
                      </p>
                    </PushToRightColumn>
                  </InnerCard>
                </div>
              );
            })}
            {nextPageLoading && <Spinner />}
            <BackButtonContainer>
              <Button
                onClick={() => {
                  setSearchComplete(false);
                  setSearchText("");
                  setSearchResult([]);
                }}
              >
                Search Again
              </Button>
            </BackButtonContainer>
          </>
        ) : (
          searchError && <RedHeader>{searchError}</RedHeader>
        )}
      </>
    </Card>
  );
};

export default SearchUsers;
