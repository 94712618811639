import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  collection,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";

import { firestore } from "../../../firebase/config";
import {
  AmberP,
  BackButtonContainer,
  GreenHeader,
  GreenP,
  PushToLeftColumn,
  PushToRightColumn,
  RedHeader,
} from "../../../global.styles";
import Card from "../../UI/card/card.component";
import InnerCard from "../../UI/inner-card/inner-card.component";
import Spinner from "../../UI/spinner/spinner.component";
import { RootState } from "../../../redux/store";
import { BookingType } from "../../../redux/bookings/bookings.types";
import Button from "../../UI/button/button.component";
import { FaDog } from "react-icons/fa";
import {
  defineVehicleType,
  transformTimestampDateOnly,
} from "../../../util-functions";
import { isApprovedDriver } from "../../../redux/user/user.types";

const PublicJobList = () => {
  const navigate = useNavigate();

  const isFirstMount = useRef(true); // Track initial mount to prevent errors on double mount

  const { userLoading, userError, currentUser } = useSelector(
    (state: RootState) => state.user
  );
  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobsError, setJobsError] = useState("");
  const [jobs, setJobs] = useState<BookingType[]>([]);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(); // To track the last document for pagination
  const [paginationOn, setPaginationOn] = useState(false);

  const timeStampNow = new Date().getTime();

  const fetchJobs = useCallback(
    async (startAfterDoc: DocumentData | null = null) => {
      if (!!approvedDriver && isApprovedDriver(approvedDriver)) {
        setJobsLoading(true);
        setJobsError("");
        try {
          let jobsQuery = query(
            collection(firestore, "bookings"),
            where("driverNumber", "==", ""),
            where("vehicleType", "in", approvedDriver.selectedVehicleTypes),
            where("completed", "==", false),
            orderBy("pickupTimestamp"),
            limit(10)
          );
          if (!approvedDriver.petFriendly) {
            jobsQuery = query(jobsQuery, where("hasPet", "==", false));
          }
          if (startAfterDoc) {
            jobsQuery = query(jobsQuery, startAfter(startAfterDoc));
          }
          const jobsSnapshot = await getDocs(jobsQuery);
          const newJobs = jobsSnapshot.docs.map((docSnapshot) => {
            const data = docSnapshot.data();
            return {
              ...data,
              //transforming timestamps from non-serializable to milliseconds number
              createdAt: data.createdAt ? data.createdAt.toMillis() : null, // Check if createdAt exists
              acceptedAt: data.acceptedAt ? data.acceptedAt.toMillis() : null, // Check if acceptedAt exists
              pickedUpAt: data.pickedUpAt ? data.pickedUpAt.toMillis() : null, // Check if pickedUpAt exists
              //convert pickupGeoPoint into serializable object for Redux store
              pickupGeoPoint: {
                latitude: docSnapshot.data()!.pickupGeoPoint.latitude,
                longitude: docSnapshot.data()!.pickupGeoPoint.longitude,
              },
              id: docSnapshot.id,
            } as BookingType;
          });
          if (jobsSnapshot.docs.length > 0) {
            setJobs((prevJobs) =>
              prevJobs ? [...prevJobs, ...newJobs] : newJobs
            );
            // Update lastVisible for pagination
            setLastVisible(jobsSnapshot.docs[jobsSnapshot.docs.length - 1]);
          }
          if (jobsSnapshot.docs.length > 9) {
            setPaginationOn(true);
          } else {
            setPaginationOn(false);
          }
        } catch (error) {
          if (error instanceof Error) {
            setJobsError(error.message);
          } else {
            setJobsError("Error fetching Jobs");
          }
        } finally {
          setJobsLoading(false);
        }
      }
    },
    [approvedDriver]
  );

  //initial fetch
  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false; // Set to false after the first mount
      fetchJobs();
    }
  }, [fetchJobs]);

  // Infinite scroll logic
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastJobRef = useRef<HTMLDivElement | null>(null); // Reference to the last booking element
  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (paginationOn && entries[0].isIntersecting && lastVisible) {
          fetchJobs(lastVisible);
        }
      },
      { rootMargin: "200px" }
    );
    observerRef.current = observer; // Assign the observer to the ref
    // Observe the target element only if it exists
    if (lastJobRef.current) {
      observer.observe(lastJobRef.current);
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [fetchJobs, lastVisible, paginationOn]);

  if (userLoading || approvedDriverLoading) return <Spinner />;

  if (userError)
    return (
      <Card>
        <RedHeader>{userError}</RedHeader>
        <BackButtonContainer>
          <Button onClick={() => navigate("/drivers")}>Ok</Button>
        </BackButtonContainer>
      </Card>
    );
  if (approvedDriverError)
    return (
      <Card>
        <RedHeader>{approvedDriverError}</RedHeader>
        <BackButtonContainer>
          <Button onClick={() => navigate("/drivers")}>Ok</Button>
        </BackButtonContainer>
      </Card>
    );

  if (
    !userLoading &&
    !approvedDriverLoading &&
    (!currentUser?.readyToDrive || !approvedDriver)
  )
    return (
      <Card>
        <GreenHeader>Public Jobs</GreenHeader>
        <AmberP>
          Public jobs information is available only for Drivers with the Active
          Status!
        </AmberP>
        <BackButtonContainer>
          <Button onClick={() => navigate("/drivers/application")}>
            Check Driver Profile
          </Button>
        </BackButtonContainer>
      </Card>
    );

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/drivers/driver-panel">
          <h3>&larr; Driver Menu</h3>
        </Link>
      </BackButtonContainer>
      <GreenHeader>List of Public Jobs </GreenHeader>
      {jobsError && <RedHeader>{jobsError}</RedHeader>}
      {jobs.length === 0 && !jobsError && (
        <GreenP>
          Currently, there are no publicly booked jobs that suit your Driver
          profile...
        </GreenP>
      )}
      {!jobsError && jobs.length > 0 && (
        <>
          <BackButtonContainer>
            <Button onClick={() => navigate("/drivers/public-job-search")}>
              Search By Date and Location
            </Button>
          </BackButtonContainer>
          <GreenP>
            * Jobs listed below match your Driver profile - vehicle type, pet
            friendliness etc.
          </GreenP>
          {jobs.map((job, index) => {
            //server stores dates in UTC, it doesn't care about winter/summer time
            //if we use server timestamp to interract with client timestamps convert it like this:
            const serverPickupTime = new Date(job.pickupTimestamp!);
            const clientPickupTimestamp =
              serverPickupTime.getTime() +
              serverPickupTime.getTimezoneOffset() * 60 * 1000;
            return (
              <div
                key={job.id}
                ref={index === jobs.length - 1 ? lastJobRef : null} // Assign ref conditionally for observer to fetch more bookings
              >
                <InnerCard
                  key={job.id}
                  onClick={() =>
                    navigate("/drivers/public-job-details", {
                      state: {
                        job,
                        searchParams: null,
                      },
                    })
                  }
                >
                  <PushToLeftColumn>
                    <h3>
                      {transformTimestampDateOnly(
                        new Date(job.searchDate).getTime()
                      )}
                      , {job.time}
                    </h3>
                    <p>{job.pickupPostCode}</p>
                    <p>{job.distanceInMiles} miles trip</p>
                    {timeStampNow > Number(clientPickupTimestamp) &&
                      !job.arrivedToPickup && <AmberP>RUNNING LATE!</AmberP>}
                  </PushToLeftColumn>
                  <PushToRightColumn>
                    <h3>£{job.quotedPrice}</h3>
                    <p>{defineVehicleType(job.vehicleType)}</p>
                    {job.hasPet && (
                      <GreenP>
                        <FaDog /> Pet
                      </GreenP>
                    )}
                  </PushToRightColumn>
                </InnerCard>
              </div>
            );
          })}
        </>
      )}
      {jobsLoading && <Spinner />}
    </Card>
  );
};

export default PublicJobList;
