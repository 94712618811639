import { FC } from "react";

import {
  SpacerHorizontal,
  SpacerVertical,
} from "../UI/spacers/spacers.component";
import { GreenP } from "../../global.styles";
import { ApprovedDriverType } from "../../redux/user/user.types";

type DriverInfoProps = {
  driver: ApprovedDriverType;
};

const DriverInfo: FC<DriverInfoProps> = ({ driver }) => (
  <SpacerHorizontal $paddingRem={0.8}>
    <SpacerVertical $paddingRem={0.8}>
      <GreenP>Name:</GreenP>
      <p>{driver.name}</p>
    </SpacerVertical>
    <SpacerVertical $paddingRem={0.8}>
      <GreenP>Phone Number:</GreenP>
      <p>{driver.phoneNumber}</p>
    </SpacerVertical>
    <SpacerVertical $paddingRem={0.8}>
      <GreenP>Vehicle:</GreenP>
      <p>
        {driver.PHvehicle.color} {driver.PHvehicle.make}{" "}
        {driver.PHvehicle.model}, {driver.PHvehicle.numberPlate}
      </p>
    </SpacerVertical>
    <SpacerVertical $paddingRem={0.8}>
      <GreenP>Licence:</GreenP>
      <p>
        Issued by {driver.licensingAuthority}.
        <ul>
          <li>Badge: {driver.badgeNumber}</li>
          <li>Plate: {driver.PHvehicle.licencePlate}</li>
        </ul>
      </p>
    </SpacerVertical>
  </SpacerHorizontal>
);

export default DriverInfo;
