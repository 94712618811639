import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  query,
} from "firebase/firestore";

import { firestore } from "../../firebase/config";
import Card from "../UI/card/card.component";
import Spinner from "../UI/spinner/spinner.component";
import ErrorMessage from "../UI/error-message/error-message.component";
import InnerCard from "../UI/inner-card/inner-card.component";
import {
  AmberP,
  BackButtonContainer,
  ButtonColumnContainer,
  ButtonContainer,
  GreenHeader,
  GreenP,
  RedHeader,
  RedP,
  SpreadApartArea,
  TextDivider,
} from "../../global.styles";
import { colors } from "../../style-variables";
import { transformTimestamp } from "../../util-functions";
import ApproveDriverPaperwork from "./forms/approve-driver-paperwork.component";
import Button from "../UI/button/button.component";
import { SpacerHorizontal } from "../UI/spacers/spacers.component";
import SetLicenceNumbersForm from "./forms/set-licence-numbers-form.component";
import Modal from "../UI/modal/modal.component";
import { FaCheck } from "react-icons/fa";
import { CurrentUserType } from "../../redux/user/user.types";
import {
  addRemoveAdminRights,
  changeDriverStatus,
  removeDriverApprovalWaiting,
  verifyDvlaCheckCode,
} from "../../firebase/admin";
import { PHVehicleType } from "../../redux/vehicles/vehicles.types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const required = <RedP>Not Provided</RedP>;
const rejected = <RedP>Rejected</RedP>;
const pending = <AmberP>Waiting for approval...</AmberP>;
const approved = <GreenP>Approved</GreenP>;

const UserDetails = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const { currentUser } = useSelector((state: RootState) => state.user);

  //user
  const [selectedUserLoading, setSelectedUserLoading] = useState(false);
  const [selectedUserError, setSelectedUserError] = useState("");
  const [selectedUser, setSelectedUser] = useState<CurrentUserType | null>(
    null
  );
  //user's cars
  const [vehicleListLoading, setVehicleListLoading] = useState(false);
  const [vehicleListError, setVehicleListError] = useState("");
  const [vehicleList, setVehicleList] = useState<PHVehicleType[]>([]);
  //modal to confirm vehicle activation or suspension (approve or ban)
  const [openModal, setOpenModal] = useState(false);
  const [approve, setApprove] = useState<boolean | null>(null);
  //modal to reset waiting for approval status
  const [openResetWaitModal, setOpenResetWaitModal] = useState(false);
  //modal to reset waiting for approval status
  const [openAdminRightsModal, setOpenAdminRightsModal] = useState(false);
  const [grant, setGrant] = useState<boolean | null>(null);

  //paperwork
  const [showLicenceNumersForm, setShowLicenceNumersForm] = useState(false);
  const [showBadgeForm, setShowBadgeForm] = useState(false);
  const [showPHLicenceForm, setShowPHLicenceForm] = useState(false);
  const [showDvlaLicenceForm, setShowDvlaLicenceForm] = useState(false);
  const [showDvlaCodeForm, setShowDvlaCodeForm] = useState(false);

  //set onSnapshot (real time updates connection) for this user
  useEffect(() => {
    setSelectedUserLoading(true);
    if (userId) {
      const unsubscribe = onSnapshot(
        doc(firestore, "users", userId),
        { includeMetadataChanges: true },
        (userDoc) => {
          if (userDoc.exists()) {
            // Handle document existence
            if (!userDoc.metadata.hasPendingWrites) {
              // Data is up-to-date
              const updatedUser = {
                id: userDoc.id,
                ...userDoc.data(),
                createdAt: userDoc.data()!.createdAt.toMillis(),
                lastUpdate: userDoc.data()!.lastUpdate.toMillis(),
              };
              setSelectedUser(updatedUser as CurrentUserType);
              setSelectedUserLoading(false);
            } else if (
              userDoc.metadata.hasPendingWrites &&
              !userDoc.metadata.fromCache
            ) {
              // Data is being updated, show a loading indicator
              setSelectedUserLoading(true); // Loading state
              // Clear existing data
              // ... (Display a loading indicator)
            } else if (
              userDoc.metadata.hasPendingWrites &&
              userDoc.metadata.fromCache
            ) {
              // Data is outdated, refresh from the server
              setSelectedUserLoading(true); // Loading state
              // Trigger a refresh from the server
              getDoc(doc(firestore, "users", userId))
                .then((docSnap) => {
                  if (docSnap.exists()) {
                    const updatedUser = {
                      id: docSnap.id,
                      ...docSnap.data(),
                      createdAt: docSnap.data()!.createdAt.toMillis(),
                      lastUpdate: docSnap.data()!.lastUpdate.toMillis(),
                    };
                    setSelectedUser(updatedUser as CurrentUserType);
                    setSelectedUserLoading(false);
                  } else {
                    // Handle document not found
                    setSelectedUserError("User not found");
                    setSelectedUserLoading(false);
                    // ... (Display an error message or redirect)
                  }
                })
                .catch((error) => {
                  if (error instanceof Error) {
                    setSelectedUserError(error.message);
                    setSelectedUserLoading(false);
                  } else {
                    setSelectedUserError("Error fetching user");
                    setSelectedUserLoading(false);
                  }
                });
            }
          } else {
            // Handle document not found
            setSelectedUserError("User not found");
            setSelectedUserLoading(false);
            // ... (Display an error message or redirect)
          }
        },
        (error) => {
          if (error instanceof Error) {
            setSelectedUserError(error.message);
            setSelectedUserLoading(false);
          } else {
            setSelectedUserError("Error fetching a user");
            setSelectedUserLoading(false);
          }
        }
      );
      return () => unsubscribe();
    } else {
      navigate("/boss");
    }
  }, [navigate, userId]);

  //set onSnapshot (real time updates connection) for this Driver's vehicles
  useEffect(() => {
    setVehicleListLoading(true);
    let unsubscribe: (() => void) | null = null; // Initialize as null
    if (userId) {
      const vehiclesRef = collection(firestore, "users", userId, "PHVehicles");
      const vehiclesQuery = query(vehiclesRef, limit(10));
      unsubscribe = onSnapshot(
        vehiclesQuery,
        { includeMetadataChanges: true },
        (snapshot) => {
          setVehicleListLoading(false);
          if (!snapshot.metadata.hasPendingWrites) {
            const updatedVehicles = snapshot.docs.map((docSnapshot) => {
              //transforming timestamps from non-serializable to milliseconds number
              return {
                ...docSnapshot.data(),
                createdAt: docSnapshot.data()!.createdAt.toMillis(),
                lastUpdate: docSnapshot.data()!.lastUpdate.toMillis(),
              };
            });
            setVehicleList(updatedVehicles as PHVehicleType[]);
          }
        },
        (error) => {
          if (error instanceof Error) {
            setVehicleListError(error.message);
          } else {
            setVehicleListError("Error fetching vehicles");
          }
        }
      );
    } else {
      navigate("/boss");
    }
    // Cleanup function is always defined
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [navigate, userId]);

  const navigateToVehicleDetailsHandler = (numberPlate: string) => {
    navigate(`/boss/vehicle-details/${userId}/${numberPlate}`);
  };

  //DVLA Check Code handler
  const dvlaCodeHandler = (isVerified: boolean) => {
    if (userId) {
      try {
        verifyDvlaCheckCode(userId, isVerified);
      } catch (error) {
        if (error instanceof Error) {
          setSelectedUserError(error.message);
        } else {
          setSelectedUserError(
            "Error approving DVLA CHeck Code for the Driver."
          );
        }
      }
    }
    setShowDvlaCodeForm(false);
  };

  //reset "waitng for approval as a Driver" boolean
  const resetWaitingHandler = () => {
    setOpenResetWaitModal(true);
  };
  const resetWaitingConfirm = async () => {
    if (userId) {
      try {
        await removeDriverApprovalWaiting(userId);
      } catch (error) {
        if (error instanceof Error) {
          setSelectedUserError(error.message);
        } else {
          setSelectedUserError(
            "Error changing waiting for approval status for the Driver"
          );
        }
      }
    }
  };
  //activate of suspend Driver
  const activationHandler = (activate: boolean) => {
    setApprove(activate);
    setOpenModal(true);
  };
  const activationConfirm = async () => {
    if (userId && approve !== null) {
      try {
        await changeDriverStatus(userId, approve);
      } catch (error) {
        if (error instanceof Error) {
          setSelectedUserError(error.message);
        } else {
          setSelectedUserError(
            "Error changing waiting for approval status for the Driver"
          );
        }
      }
    }
  };
  //grant or remove admon rights
  const adminRightsHandler = (grant: boolean) => {
    setGrant(grant);
    setOpenAdminRightsModal(true);
  };
  const adminRightsConfirm = async () => {
    if (selectedUser && grant !== null) {
      try {
        await addRemoveAdminRights(selectedUser.id, grant);
      } catch (error) {
        if (error instanceof Error) {
          setSelectedUserError(error.message);
        } else {
          setSelectedUserError("Error changing Admin rights for this user");
        }
      }
    }
  };

  return (
    <>
      {selectedUserLoading && <Spinner />}
      {selectedUserError && (
        <ErrorMessage slice="selectedUser">{selectedUserError}</ErrorMessage>
      )}
      {selectedUser && (
        <Card>
          {openModal && approve !== null && (
            <Modal
              title={approve ? "Activate" : "Suspend"}
              body={`${
                approve ? "Activate" : "Suspend"
              } this Driver? Are you sure?`}
              confirmButtonText={approve ? "Activate" : "Suspend"}
              setOpenModal={setOpenModal}
              onModalConfirm={() => activationConfirm()}
            />
          )}
          {openResetWaitModal && (
            <Modal
              title="Reset Waiting Status"
              body="Have all paperwork, data and vehicles for this Driver been reviewed? Are you sure?"
              confirmButtonText="Reset"
              setOpenModal={setOpenResetWaitModal}
              onModalConfirm={resetWaitingConfirm}
            />
          )}
          {openAdminRightsModal && grant !== null && (
            <Modal
              title={grant ? "Make Admin" : "Disable as Admin"}
              body={`${
                grant ? "Grant Admin rights to " : "Remove Admin rights from "
              } ${selectedUser.name}? Are you sure?`}
              confirmButtonText={grant ? "Grant!" : "Remove!"}
              setOpenModal={setOpenAdminRightsModal}
              onModalConfirm={adminRightsConfirm}
            />
          )}

          <BackButtonContainer>
            <Link to="/boss">
              <h3>&larr; Admin Menu</h3>
            </Link>
          </BackButtonContainer>

          <GreenHeader>{selectedUser.name}</GreenHeader>

          <TextDivider>
            <GreenP>CONTACTS</GreenP>
          </TextDivider>
          <SpreadApartArea>
            <GreenP>Email:</GreenP>
            <p>{selectedUser.email}</p>
          </SpreadApartArea>
          <SpreadApartArea>
            <GreenP>Phone:</GreenP>
            {selectedUser.phoneNumber ? (
              <p>{selectedUser.phoneNumber}</p>
            ) : (
              <RedP>No phone number</RedP>
            )}
          </SpreadApartArea>

          <TextDivider>
            <GreenP>TIME STAMPS</GreenP>
          </TextDivider>
          <SpreadApartArea>
            <GreenP>Created:</GreenP>{" "}
            <p>{transformTimestamp(selectedUser.createdAt)}</p>
          </SpreadApartArea>
          <SpreadApartArea>
            <GreenP>Last update:</GreenP>
            <p>{transformTimestamp(selectedUser.lastUpdate)}</p>
          </SpreadApartArea>

          {selectedUser && selectedUser.driversData && (
            <>
              <TextDivider>
                <GreenP>DRIVERS DATA</GreenP>
              </TextDivider>

              <SpreadApartArea>
                <GreenP>Licence:</GreenP>
                <p>
                  <strong>{selectedUser.driversData.licensingAuthority}</strong>
                </p>
              </SpreadApartArea>

              <SpreadApartArea>
                <GreenP>Driver Number:</GreenP>
                {selectedUser.driverNumber ? (
                  <p>{selectedUser.driverNumber}</p>
                ) : (
                  <RedP>Not assigned</RedP>
                )}
              </SpreadApartArea>

              <InnerCard
                onClick={() => {
                  setShowLicenceNumersForm(!showLicenceNumersForm);
                }}
              >
                <h3>Licence Numbers</h3>
                {selectedUser.driversData.dvlaLicenceNumber &&
                selectedUser.driversData.badgeNumber ? (
                  <div>
                    <GreenP>
                      {selectedUser.driversData.dvlaLicenceNumber}
                    </GreenP>
                    <GreenP>{selectedUser.driversData.badgeNumber}</GreenP>
                  </div>
                ) : (
                  required
                )}
              </InnerCard>
              {showLicenceNumersForm && userId && (
                <SetLicenceNumbersForm
                  selectedUser={selectedUser}
                  onCancelSet={() => setShowLicenceNumersForm(false)}
                  onError={setSelectedUserError}
                />
              )}

              <InnerCard onClick={() => setShowBadgeForm(!showBadgeForm)}>
                <h3>Private Hire Badge</h3>
                {selectedUser.driversData.PHBadge &&
                selectedUser.driversData.PHBadge.pendingUrl ? (
                  pending
                ) : selectedUser.driversData.PHBadge &&
                  selectedUser.driversData.PHBadge.approved ? (
                  approved
                ) : selectedUser.driversData.PHBadge &&
                  selectedUser.driversData.PHBadge.reasonOfDecline ? (
                  <RedP>
                    {selectedUser.driversData.PHBadge.reasonOfDecline}
                  </RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showBadgeForm &&
                (selectedUser.driversData.PHBadge?.pendingUrl ||
                  selectedUser.driversData.PHBadge?.downloadUrl ||
                  selectedUser.driversData.PHBadge?.expiringUrl) && (
                  <ApproveDriverPaperwork
                    driver={selectedUser}
                    docTitle="PHBadge"
                    onDocumentReviewed={() => setShowBadgeForm(false)}
                    onError={(error: string) => setSelectedUserError(error)}
                  />
                )}

              <InnerCard
                onClick={() => setShowPHLicenceForm(!showPHLicenceForm)}
              >
                <h3>PH Driver paper licence</h3>
                {selectedUser.driversData.PHDriversLicence &&
                selectedUser.driversData.PHDriversLicence.pendingUrl ? (
                  pending
                ) : selectedUser.driversData.PHDriversLicence &&
                  selectedUser.driversData.PHDriversLicence.approved ? (
                  approved
                ) : selectedUser.driversData.PHDriversLicence &&
                  selectedUser.driversData.PHDriversLicence.reasonOfDecline ? (
                  <RedP>
                    {selectedUser.driversData.PHDriversLicence.reasonOfDecline}
                  </RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showPHLicenceForm &&
                (selectedUser.driversData.PHDriversLicence?.pendingUrl ||
                  selectedUser.driversData.PHDriversLicence?.downloadUrl ||
                  selectedUser.driversData.PHDriversLicence?.expiringUrl) && (
                  <ApproveDriverPaperwork
                    driver={selectedUser}
                    docTitle="PHDriversLicence"
                    onDocumentReviewed={() => setShowPHLicenceForm(false)}
                    onError={(error: string) => setSelectedUserError(error)}
                  />
                )}

              <InnerCard
                onClick={() => setShowDvlaLicenceForm(!showDvlaLicenceForm)}
              >
                <h3>DVLA licence</h3>
                {selectedUser.driversData.DVLALicence &&
                selectedUser.driversData.DVLALicence.pendingUrl ? (
                  pending
                ) : selectedUser.driversData.DVLALicence &&
                  selectedUser.driversData.DVLALicence.approved ? (
                  approved
                ) : selectedUser.driversData.DVLALicence &&
                  selectedUser.driversData.DVLALicence.reasonOfDecline ? (
                  <RedP>
                    {selectedUser.driversData.DVLALicence.reasonOfDecline}
                  </RedP>
                ) : (
                  required
                )}
              </InnerCard>
              {showDvlaLicenceForm &&
                (selectedUser.driversData.DVLALicence?.pendingUrl ||
                  selectedUser.driversData.DVLALicence?.downloadUrl ||
                  selectedUser.driversData.DVLALicence?.expiringUrl) && (
                  <ApproveDriverPaperwork
                    driver={selectedUser}
                    docTitle="DVLALicence"
                    onDocumentReviewed={() => setShowDvlaLicenceForm(false)}
                    onError={(error: string) => setSelectedUserError(error)}
                  />
                )}

              <InnerCard onClick={() => setShowDvlaCodeForm(!showDvlaCodeForm)}>
                <h3>DVLA Check Code</h3>
                {selectedUser.driversData.DVLACheckCode &&
                selectedUser.driversData.DVLACheckCode.approved
                  ? approved
                  : selectedUser.driversData.DVLACheckCode &&
                    selectedUser.driversData.DVLACheckCode.pending
                  ? pending
                  : selectedUser.driversData.DVLACheckCode &&
                    !selectedUser.driversData.DVLACheckCode.pending
                  ? rejected
                  : required}
              </InnerCard>
              {showDvlaCodeForm &&
                (selectedUser.driversData.DVLACheckCode?.pending ||
                  selectedUser.driversData.DVLACheckCode?.code) && (
                  <ButtonColumnContainer>
                    <GreenHeader>
                      {selectedUser.driversData.DVLACheckCode?.code}
                    </GreenHeader>
                    <ButtonContainer>
                      <Button
                        buttonType="inverted"
                        onClick={dvlaCodeHandler.bind(null, false)}
                      >
                        Reject
                      </Button>
                      <SpacerHorizontal $paddingRem={2} />
                      <Button onClick={dvlaCodeHandler.bind(null, true)}>
                        Approve
                      </Button>
                    </ButtonContainer>
                  </ButtonColumnContainer>
                )}

              <TextDivider>
                <GreenP>VEHICLES</GreenP>
              </TextDivider>
              {vehicleListLoading && <Spinner />}
              {vehicleListError && (
                <ErrorMessage slice="selectedUser">
                  {vehicleListError}
                </ErrorMessage>
              )}
              {vehicleList &&
                vehicleList.length > 0 &&
                vehicleList.map((vehicle) => (
                  <InnerCard
                    key={vehicle.numberPlate}
                    onClick={navigateToVehicleDetailsHandler.bind(
                      null,
                      vehicle.numberPlate
                    )}
                  >
                    <h3>
                      {vehicle.numberPlate}{" "}
                      {vehicle.selected && (
                        <FaCheck size={16} color={colors.light} />
                      )}
                    </h3>
                    {vehicle.waitingApproval
                      ? pending
                      : vehicle.approved
                      ? approved
                      : required}
                  </InnerCard>
                ))}
              {selectedUser?.waitingDriverApproval && (
                <>
                  <TextDivider>
                    <GreenP>WAITING FOR APPROVAL</GreenP>
                  </TextDivider>
                  <ButtonColumnContainer>
                    <Button onClick={resetWaitingHandler}>
                      Remove From Waiting...
                    </Button>
                  </ButtonColumnContainer>
                </>
              )}
              <TextDivider>
                <GreenP>STATUS</GreenP>
              </TextDivider>
              <ButtonColumnContainer>
                {selectedUser.approvedDriver ? (
                  <GreenHeader>Driver is Active</GreenHeader>
                ) : (
                  <RedHeader>Driver is NOT Active</RedHeader>
                )}
              </ButtonColumnContainer>
              <ButtonContainer>
                <Button
                  buttonType="warning"
                  onClick={activationHandler.bind(null, false)}
                >
                  Suspend
                </Button>
                <Button
                  buttonType="green"
                  onClick={activationHandler.bind(null, true)}
                >
                  Activate
                </Button>
              </ButtonContainer>
            </>
          )}
          {currentUser?.mega && (
            <>
              <TextDivider>
                <GreenP>ADMIN OF ADMINS</GreenP>
              </TextDivider>
              <ButtonColumnContainer>
                {selectedUser.isBoss ? (
                  <>
                    <GreenP>{selectedUser.name} is an Admin</GreenP>
                    <BackButtonContainer>
                      <Button onClick={adminRightsHandler.bind(null, false)}>
                        Remove Admin Rights
                      </Button>
                    </BackButtonContainer>
                  </>
                ) : (
                  <>
                    <GreenP>{selectedUser.name} is NOT an Admin</GreenP>
                    <BackButtonContainer>
                      <Button onClick={adminRightsHandler.bind(null, true)}>
                        Grant Admin Rights
                      </Button>
                    </BackButtonContainer>
                  </>
                )}
              </ButtonColumnContainer>
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default UserDetails;
