import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  collection,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";

import Card from "../UI/card/card.component";
import { RootState } from "../../redux/store";
import Spinner from "../UI/spinner/spinner.component";
import {
  BackButtonContainer,
  GreenHeader,
  GreenP,
  RedHeader,
  SpaceAroundArea,
} from "../../global.styles";
import Button from "../UI/button/button.component";
import FormInput from "../UI/form-input/form-input.component";
import { firestore } from "../../firebase/config";
import { BookingType } from "../../redux/bookings/bookings.types";
import InnerCard from "../UI/inner-card/inner-card.component";
import BookingStatus from "./booking-status/booking-status.component";
import { transformTimestampDateOnly } from "../../util-functions";

const BookingsSearch = () => {
  const navigate = useNavigate();

  const { userLoading, userError, currentUser } = useSelector(
    (state: RootState) => state.user
  );

  const [selectedDate, setSelectedDate] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState("");
  const [searchComplete, setSearchComplete] = useState(false);
  const [bookings, setBookings] = useState<BookingType[]>([]);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(); // To track the last document for pagination
  const [paginationOn, setPaginationOn] = useState(false);

  //function that fetches the results
  const searchBookings = useCallback(
    async (searchDate: string, startAfterDoc: DocumentData | null = null) => {
      const bookingsColRef = collection(firestore, "bookings");
      let bookingsQuery = query(
        bookingsColRef,
        where("userId", "==", currentUser?.id),
        where("searchDate", "==", searchDate),
        orderBy("pickupTimestamp"),
        limit(10)
      );
      if (startAfterDoc) {
        bookingsQuery = query(bookingsQuery, startAfter(startAfterDoc));
      }
      setSearchLoading(true);
      try {
        const bookingsSnapshot = await getDocs(bookingsQuery);
        const fetchedBookings = bookingsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            //transforming timestamps from non-serializable to milliseconds number
            createdAt: data.createdAt ? data.createdAt.toMillis() : null, // Check if createdAt exists
            acceptedAt: data.acceptedAt ? data.acceptedAt.toMillis() : null, // Check if acceptedAt exists
            pickedUpAt: data.pickedUpAt ? data.pickedUpAt.toMillis() : null, // Check if pickedUpAt exists
            //convert pickupGeoPoint into serializable object for Redux store
            pickupGeoPoint: {
              latitude: data.pickupGeoPoint.latitude,
              longitude: data.pickupGeoPoint.longitude,
            },
          } as BookingType;
        });
        if (bookingsSnapshot.docs.length > 0) {
          setBookings((prevBookings) =>
            prevBookings
              ? [...prevBookings, ...fetchedBookings]
              : fetchedBookings
          );
          // Update lastVisible for pagination
          setLastVisible(
            bookingsSnapshot.docs[bookingsSnapshot.docs.length - 1]
          );
        }
        if (bookingsSnapshot.docs.length > 9) {
          setPaginationOn(true);
        } else {
          setPaginationOn(false);
        }
      } catch (error) {
        if (error instanceof Error) {
          setSearchError(error.message);
        } else {
          setSearchError("Error fetching bookings");
        }
      } finally {
        setSearchLoading(false);
      }
    },
    [currentUser?.id]
  );

  // Infinite scroll logic
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastBookingRef = useRef<HTMLDivElement | null>(null); // Reference to the last booking element
  useEffect(() => {
    //check if initial search is complete and we have searching parameters
    if (searchComplete && selectedDate && paginationOn && lastVisible) {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      const observer = new IntersectionObserver(
        (entries) => {
          if (
            paginationOn &&
            selectedDate &&
            entries[0].isIntersecting &&
            lastVisible
          ) {
            searchBookings(selectedDate, lastVisible);
          }
        },
        { rootMargin: "200px" }
      );
      observerRef.current = observer; // Assign the observer to the ref
      // Observe the target element only if it exists
      if (lastBookingRef.current) {
        observer.observe(lastBookingRef.current);
      }
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [searchBookings, lastVisible, selectedDate, searchComplete, paginationOn]);

  const selectedDateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedDate === "" || typeof selectedDate !== "string") {
      setSearchError("Please enter a date");
      return;
    }
    //new search - reset bookings
    setBookings([]);
    await searchBookings(selectedDate);
    setSearchComplete(true);
  };

  if (userLoading) return <Spinner />;

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/">
          <h3>Go Back</h3>
        </Link>
      </BackButtonContainer>
      <GreenHeader>Search Bookings by Date</GreenHeader>
      {userError && <RedHeader>{userError}</RedHeader>}
      <form onSubmit={submitHandler}>
        <SpaceAroundArea>
          <FormInput
            label=""
            id="search-date"
            type="date"
            onChange={selectedDateChangeHandler}
            value={selectedDate}
            required
          />
          <BackButtonContainer>
            <Button type="submit">Search</Button>
          </BackButtonContainer>
        </SpaceAroundArea>
      </form>

      {searchError && <RedHeader>{searchError}</RedHeader>}

      {searchComplete &&
        selectedDate &&
        (!bookings || bookings.length === 0) &&
        !searchError && (
          <GreenP>{`No bookings found for ${transformTimestampDateOnly(
            new Date(selectedDate).getTime()
          )}`}</GreenP>
        )}
      {!!bookings &&
        !searchError &&
        bookings.length > 0 &&
        bookings.map((booking, index) => {
          //server stores dates in UTC, it doesn't care about winter/summer time
          //if you use server timestamp to interract with client timestamps convert it like this:
          // const serverPickupTime = new Date(booking.pickupTimestamp!);
          // const clientPickupTimestamp = serverPickupTime.setTime(
          //   serverPickupTime.getTime() +
          //     serverPickupTime.getTimezoneOffset() * 60 * 1000
          // );
          return (
            <div
              key={booking.id}
              ref={index === bookings.length - 1 ? lastBookingRef : null} // Assign ref conditionally for observer to fetch more bookings
            >
              <InnerCard
                onClick={() => {
                  navigate(`/bookings/booking-details/${booking.id}`);
                }}
              >
                <div>
                  <p>{booking.date}</p>
                  <p>
                    <strong>{booking.time}</strong>
                  </p>
                </div>
                <BookingStatus booking={booking} />
              </InnerCard>
            </div>
          );
        })}
      {searchLoading && <Spinner />}
    </Card>
  );
};

export default BookingsSearch;
