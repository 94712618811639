import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  FaEnvelope,
  FaHandshake,
  FaList,
  FaRegClock,
  FaSearch,
} from "react-icons/fa";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { GrMoney } from "react-icons/gr";

import LogoImage from "../../assets/images/Color logo with background.svg";

import Card from "../../components/UI/card/card.component";
import {
  BackButtonContainer,
  ButtonColumnContainer,
  GreenHeader,
  GreenP,
  SpeechBubble,
  TextDivider,
} from "../../global.styles";
import { RootState } from "../../redux/store";
import Spinner from "../../components/UI/spinner/spinner.component";
import InnerCard from "../../components/UI/inner-card/inner-card.component";
import Button from "../../components/UI/button/button.component";
import ErrorMessage from "../../components/UI/error-message/error-message.component";
import { EmailLoginButton } from "./home.styles";
import {
  facebookSignInStart,
  googleSignInStart,
} from "../../redux/user/user.slice";

const Home = () => {
  const dispatch = useDispatch();
  const { userLoading, currentUser, userError } = useSelector(
    (state: RootState) => state.user
  );

  const [showPricingText, setShowPricingText] = useState(false);
  const [showHandShakeText, setShowHandShakeText] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser && !currentUser.name) {
      navigate("/profile");
    }
  }, [currentUser, navigate]);

  const navigateToLoginHandler = () => {
    navigate("/login");
  };

  //speach bubles
  const flipPricingText = () => {
    setShowPricingText(!showPricingText);
  };
  const flipHandShakeText = () => {
    setShowHandShakeText(!showHandShakeText);
  };

  //dispatch social media sign-ins
  const googleSigninHandler = () => {
    dispatch(googleSignInStart());
  };
  const facebookSigninHandler = () => {
    dispatch(facebookSignInStart());
  };

  return (
    <Card>
      {userLoading && <Spinner />}
      {userError && <ErrorMessage slice="user">{userError}</ErrorMessage>}

      {!userLoading && !currentUser && (
        <>
          <img src={LogoImage} width="100%" alt="Rufus" />
          <GreenHeader>
            RELIABILITY and Competitive PRICING are our main goals:
          </GreenHeader>
          <InnerCard onClick={flipHandShakeText}>
            <FaHandshake size={32} />
            <div>
              <h3>RELIABILITY</h3>
            </div>
          </InnerCard>
          {showHandShakeText && (
            <SpeechBubble onClick={flipHandShakeText}>
              Plan ahead with confidence: pre-book your trips and even choose
              your preferred Driver. We connect you with your Driver as soon as
              your booking is confirmed, ensuring clear communication well in
              advance of your ride.
            </SpeechBubble>
          )}
          <InnerCard onClick={flipPricingText}>
            <GrMoney size={32} />
            <div>
              <h3>PRICING</h3>
            </div>
          </InnerCard>
          {showPricingText && (
            <SpeechBubble onClick={flipPricingText}>
              Fair pricing for quality service: with no or minimal service fees
              for Drivers, our fares stay competitive with similar providers.
              While not the cheapest, we ensure Drivers are paid fairly to
              guarantee reliability, motivation, and punctuality for every
              journey.
            </SpeechBubble>
          )}
          <br />
          <GreenHeader>To start using our services, please</GreenHeader>
          <ButtonColumnContainer>
            <GoogleLoginButton onClick={googleSigninHandler} />
            <FacebookLoginButton onClick={facebookSigninHandler} />{" "}
          </ButtonColumnContainer>
          <TextDivider>
            <GreenP> OR </GreenP>
          </TextDivider>
          <ButtonColumnContainer>
            <EmailLoginButton onClick={navigateToLoginHandler}>
              <FaEnvelope size={32} style={{ marginRight: "1rem" }} />
              Log In With Email
            </EmailLoginButton>
          </ButtonColumnContainer>
        </>
      )}

      {!userLoading && currentUser && currentUser.name && (
        <>
          <GreenHeader>Welcome, {currentUser.name}!</GreenHeader>
          <BackButtonContainer>
            <Button onClick={() => navigate("/bookings/quote-form")}>
              Book a Trip!
            </Button>
          </BackButtonContainer>
          <TextDivider>
            <GreenP> MY TRIPS </GreenP>
          </TextDivider>
          <InnerCard onClick={() => navigate("/bookings")}>
            <FaRegClock size={32} />
            <h3>Active Trips</h3>
          </InnerCard>
          <InnerCard onClick={() => navigate("/bookings/history")}>
            <FaList size={32} />
            <h3>Trips History</h3>
          </InnerCard>
          <InnerCard onClick={() => navigate("/bookings/search")}>
            <FaSearch size={32} />
            <h3>Search Trips</h3>
          </InnerCard>
        </>
      )}
    </Card>
  );
};

export default Home;
