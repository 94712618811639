import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { FaDog } from "react-icons/fa";

import { firestore } from "../../../firebase/config";
import {
  AmberHeader,
  BackButtonContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  SpreadButtonContainer,
  TextDivider,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import { BookingType } from "../../../redux/bookings/bookings.types";
import Addresses from "../../bookings/addresses.component";
import { defineVehicleType } from "../../../util-functions";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import BookingStatusheader from "../../bookings/booking-status/booking-status-header";
import Button from "../../UI/button/button.component";
import {
  cancelJobRequest,
  driversBackAction,
  driversNextAction,
} from "../../../firebase/user";
import FormInput from "../../UI/form-input/form-input.component";

//function to determine text for "Next" action buttton
const nextButtonText = (job: BookingType): string => {
  if (job.pickedUp) {
    return "COMPLETE JOB";
  } else if (job.arrivedToPickup) {
    return "PICKED UP";
  } else if (job.onRoute) {
    return "ARRIVED";
  } else {
    return "ON ROUTE";
  }
};

const AcceptedJobDetails = () => {
  const navigate = useNavigate();

  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const { jobId } = useParams();

  const [actionLoading, setActionLoading] = useState(false);
  const [actionError, setActionError] = useState("");
  const [jobCancelled, setJobCancelled] = useState(false);
  const [showRejectForm, setShowRejectForm] = useState(false);
  const [reasonOfReject, setReasonOfReject] = useState("");

  const [jobDetailsLoading, setJobDetailsLoading] = useState(true);
  const [jobDetailsError, setJobDetailsError] = useState("");
  const [jobDetails, setJobDetails] = useState<BookingType | null>(null);

  //set onSnapshot (real time updates connection) for this job
  useEffect(() => {
    if (jobId && approvedDriver) {
      const unsubscribe = onSnapshot(
        doc(firestore, "bookings", jobId),
        { includeMetadataChanges: true },
        (jobDoc) => {
          if (jobDoc.exists()) {
            // Handle document existence
            if (!jobDoc.metadata.hasPendingWrites) {
              // Data is up-to-date
              const updatedBooking = {
                id: jobDoc.id,
                ...jobDoc.data(),
                createdAt: jobDoc.data().createdAt.toMillis(),
              };
              setJobDetails(updatedBooking as BookingType);
              setJobDetailsLoading(false);
            } else if (
              jobDoc.metadata.hasPendingWrites &&
              !jobDoc.metadata.fromCache
            ) {
              // Data is being updated, show a loading indicator
              setJobDetailsLoading(true); // Loading state
              // Clear existing data
              // ... (Display a loading indicator)
            } else if (
              jobDoc.metadata.hasPendingWrites &&
              jobDoc.metadata.fromCache
            ) {
              // Data is outdated, refresh from the server
              setJobDetailsLoading(true); // Loading state
              // Trigger a refresh from the server
              getDoc(doc(firestore, "bookings", jobId))
                .then((docSnap) => {
                  if (docSnap.exists()) {
                    const updatedBooking = {
                      id: docSnap.id,
                      ...docSnap.data(),
                      createdAt: docSnap.data()!.createdAt.toMillis(),
                      acceptedAt: docSnap.data()!.acceptedAt.toMillis(),
                      arrivedToPickupAt: docSnap
                        .data()!
                        .arrivedToPickupAt.toMillis(),
                      pickedUpAt: docSnap.data()!.pickedUpAt.toMillis(),
                      completedAt: docSnap.data()!.completedAt.toMillis(),
                      //convert pickupGeoPoint into serializable object for Redux store
                      pickupGeoPoint: {
                        latitude: docSnap.data()!.pickupGeoPoint.latitude,
                        longitude: docSnap.data()!.pickupGeoPoint.longitude,
                      },
                    };
                    setJobDetails(updatedBooking as BookingType);
                    setJobDetailsLoading(false);
                  } else {
                    // Handle document not found
                    setJobDetailsError("Job not found");
                    setJobDetailsLoading(false);
                    // ... (Display an error message or redirect)
                  }
                })
                .catch((error) => {
                  if (error instanceof Error) {
                    setJobDetailsError(error.message);
                    setJobDetailsLoading(false);
                  } else {
                    setJobDetailsError("Error fetching job");
                    setJobDetailsLoading(false);
                  }
                });
            }
          } else {
            // Handle document not found
            setJobDetailsError("Job not found");
            setJobDetailsLoading(false);
            // ... (Display an error message or redirect)
          }
        },
        (error) => {
          if (error instanceof Error) {
            setJobDetailsError(error.message);
            setJobDetailsLoading(false);
          } else {
            setJobDetailsError("Error fetching a job");
            setJobDetailsLoading(false);
          }
        }
      );
      return () => unsubscribe();
    } else {
      navigate("/drivers");
    }
  }, [approvedDriver, jobId, navigate]);

  const reasonChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    setReasonOfReject(event.target.value);

  const rejectActionHandler = async () => {
    if (!reasonOfReject || reasonOfReject === "") {
      setActionError(
        "Please share your reason for cancelling this booking to help us improve."
      );
      return;
    }
    if (jobId) {
      setActionLoading(true);
      try {
        const data = await cancelJobRequest(jobId, reasonOfReject);
        if (data && data.message && data.message === "Job cancelled!") {
          setJobCancelled(true);
        }
      } catch (error) {
        if (error instanceof Error) {
          setActionError(error.message);
        } else {
          setActionError(
            "Problem cancelling this job... Please try again. If problem still exists, please let us know"
          );
        }
      }
      setActionLoading(false);
    } else {
      navigate("/drivers/accepted-jobs");
    }
  };

  const cancelActionHandler = async () => {
    if (jobId) {
      setActionError("");
      setActionLoading(true);
      try {
        await driversBackAction(jobId);
      } catch (error) {
        if (error instanceof Error) {
          setActionError(error.message);
        } else {
          setActionError(
            "Problem reverting the job... Please try again. If problem still exists, please let us know"
          );
        }
      }
      setActionLoading(false);
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  const forwardActionHandler = async () => {
    if (jobId) {
      setActionError("");
      setActionLoading(true);
      try {
        await driversNextAction(jobId);
      } catch (error) {
        if (error instanceof Error) {
          setActionError(error.message);
        } else {
          setActionError(
            "Problem progressing the job... Please try again. If problem still exists, please let us know"
          );
        }
      }
      setActionLoading(false);
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  if (jobCancelled) {
    return (
      <Card>
        <GreenHeader>Job cancelled.</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => {
              navigate("/drivers/accepted-jobs");
            }}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  //we don't display job as active with all controlls if it's rejected or returned
  if (
    !jobDetailsLoading &&
    !actionLoading &&
    !!jobDetails &&
    (!jobDetails.accepted || jobDetails.completed)
  ) {
    return (
      <Card>
        <GreenHeader>
          {jobDetails?.cancelledByPassenger
            ? "Your job was cancelled by passenger, sorry."
            : "Job completed."}
        </GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => {
              navigate("/drivers/accepted-jobs");
            }}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  return (
    <Card>
      {(approvedDriverLoading || actionLoading || jobDetailsLoading) && (
        <Spinner />
      )}
      {approvedDriverError && !approvedDriverLoading && (
        <RedHeader>{approvedDriverError}</RedHeader>
      )}
      {jobDetailsError && !jobDetailsLoading && (
        <RedHeader>{jobDetailsError}</RedHeader>
      )}
      {!jobDetailsLoading && !actionLoading && !!jobDetails && (
        <>
          <BackButtonContainer>
            <ClickableArea onClick={() => navigate(-1)}>
              <h3>Go Back</h3>
            </ClickableArea>
          </BackButtonContainer>
          <GreenP>Your job on {jobDetails.date} at</GreenP>
          <GreenHeader>{jobDetails.time}</GreenHeader>
          <TextDivider>
            <BookingStatusheader booking={jobDetails} />
          </TextDivider>
          {actionError && !actionLoading && (
            <RedHeader>{actionError}</RedHeader>
          )}
          {!showRejectForm ? (
            <SpreadButtonContainer>
              {!jobDetails.onRoute ? (
                <Button
                  buttonType="inverted"
                  onClick={() => {
                    setShowRejectForm(true);
                    setActionError("");
                  }}
                >
                  Reject Job
                </Button>
              ) : (
                <Button buttonType="inverted" onClick={cancelActionHandler}>
                  Cancel
                </Button>
              )}
              <Button onClick={forwardActionHandler}>
                {nextButtonText(jobDetails)}
              </Button>
            </SpreadButtonContainer>
          ) : (
            <>
              <AmberHeader>Cancel this job?</AmberHeader>
              <FormInput
                label="Please type the reason..."
                onChange={reasonChangeHandler}
                id="reasonOfReject"
                name="reasonOfReject"
                type="text"
                value={reasonOfReject}
                required
              />
              <SpreadButtonContainer>
                <Button
                  buttonType="inverted"
                  onClick={() => {
                    setShowRejectForm(false);
                    setActionError("");
                  }}
                >
                  Keep The Job
                </Button>
                <Button onClick={rejectActionHandler}>Cancel Job</Button>
              </SpreadButtonContainer>
            </>
          )}
          <TextDivider>
            <GreenP> ADDRESSES </GreenP>
          </TextDivider>
          <Addresses booking={jobDetails} />
          <TextDivider>
            <GreenP> DETAILS </GreenP>
          </TextDivider>
          <GreenP>Passenger Name</GreenP>
          <p>{jobDetails.passengerName}</p>
          <br />
          <GreenP>Passenger Phone Number</GreenP>
          <p>{jobDetails.passengerPhone}</p>
          <br />
          <GreenP>Vehicle Type:</GreenP>
          <p>{defineVehicleType(jobDetails.vehicleType)}</p>
          {jobDetails.hasPet && (
            <>
              <br />
              <GreenP>
                <FaDog /> Booking includes pet.
              </GreenP>
            </>
          )}
          {jobDetails.driverNote && (
            <>
              <br />
              <GreenP>Note to the Driver</GreenP>
              <p>{jobDetails.driverNote}</p>
            </>
          )}
          <TextDivider>
            <GreenP> PRICE </GreenP>
          </TextDivider>
          <GreenHeader> £{jobDetails.quotedPrice}*</GreenHeader>
          <GreenP>
            * Amount to pay to the Driver. Any parking and/or toll fees and/or
            waitng time charges to be added. Please see{" "}
            <Link to="/terms">Terms and Conditions</Link>
          </GreenP>
        </>
      )}
    </Card>
  );
};

export default AcceptedJobDetails;
