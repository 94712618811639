import { ChangeEvent, FC, useState } from "react";

import { ButtonColumnContainer, RedP } from "../../../global.styles";
import FormInput from "../../UI/form-input/form-input.component";
import Button from "../../UI/button/button.component";
import { inputLicenceNumbers } from "../../../firebase/admin";
import { CurrentUserType } from "../../../redux/user/user.types";

type LicenceNumbersProps = {
  selectedUser: CurrentUserType;
  onCancelSet: () => void;
  onError: (error: string) => void;
};

const SetLicenceNumbersForm: FC<LicenceNumbersProps> = ({
  selectedUser,
  onCancelSet,
  onError,
}) => {
  const [dvlaLicenceNumber, setDvlaLicenceNumber] = useState(
    selectedUser?.driversData?.dvlaLicenceNumber
      ? selectedUser?.driversData?.dvlaLicenceNumber
      : ""
  );
  const [badgeNumber, setBadgeNumber] = useState(
    selectedUser?.driversData?.badgeNumber
      ? selectedUser?.driversData?.badgeNumber
      : ""
  );
  const [error, setError] = useState("");

  //input change handlers
  const dvlaLicenceChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setDvlaLicenceNumber(event.target.value);
  };
  const badgeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setBadgeNumber(event.target.value);
  };

  //submit handler
  const submitHandler = async () => {
    if (dvlaLicenceNumber === "" || badgeNumber === "") {
      setError("Please fill out all fields!");
      return;
    }
    try {
      await inputLicenceNumbers(
        selectedUser.id,
        dvlaLicenceNumber,
        badgeNumber
      );
    } catch (error) {
      if (error instanceof Error) {
        onError(error.message);
      } else {
        onError("Error setting licence numbers for the Driver");
      }
    }
    onCancelSet();
  };

  return (
    <ButtonColumnContainer>
      <FormInput
        label="DVLA Licence Number"
        value={dvlaLicenceNumber}
        onChange={dvlaLicenceChangeHandler}
        required
      />
      <FormInput
        label="PH Badge Numer"
        value={badgeNumber}
        onChange={badgeChangeHandler}
        required
      />
      {error && <RedP>{error}</RedP>}
      <Button type="submit" onClick={submitHandler}>
        Submit
      </Button>
    </ButtonColumnContainer>
  );
};

export default SetLicenceNumbersForm;
