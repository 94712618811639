import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  collection,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";

import { firestore } from "../../firebase/config";
import Card from "../UI/card/card.component";
import { RootState } from "../../redux/store";
import { BookingType } from "../../redux/bookings/bookings.types";
import Spinner from "../UI/spinner/spinner.component";
import {
  AmberP,
  BackButtonContainer,
  GreenHeader,
  GreenP,
  PushToLeftColumn,
  PushToRightColumn,
  RedHeader,
  SpaceAroundArea,
} from "../../global.styles";
import FormInput from "../UI/form-input/form-input.component";
import Button from "../UI/button/button.component";
import InnerCard from "../UI/inner-card/inner-card.component";
import BookingStatus from "../bookings/booking-status/booking-status.component";

const authorityOptions = [
  { value: "bristol", label: "Bristol City Council" },
  { value: "southGlos", label: "South Gloucestershire Council" },
];

const SearchBookings = () => {
  const navigate = useNavigate();

  const { userLoading, userError } = useSelector(
    (state: RootState) => state.user
  );

  const [searchError, setSearchError] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchComplete, setSearchComplete] = useState(false);
  const [searchDate, setSearchDate] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [licensingAuthority, setLicensingAuthority] = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [searchResult, setSearchResult] = useState<BookingType[]>([]);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(); // To track the last document for pagination
  const [paginationOn, setPaginationOn] = useState(false);
  const [nextPageLoading, setNextPageLoading] = useState(false);

  //search function
  const searchBookings = useCallback(
    async (startAfterDoc: DocumentData | null = null) => {
      if (
        // typeof searchDate === "string" &&
        // typeof driverNumber === "string" &&
        // typeof licensingAuthority?.label === "string"
        true
      ) {
        if (!!startAfterDoc) {
          setNextPageLoading(true);
        } else {
          setSearchLoading(true);
        }
        setSearchError("");
        try {
          let bookingsRef = collection(firestore, "bookings");
          let bookingsQuery = query(bookingsRef);
          if (searchDate) {
            bookingsQuery = query(
              bookingsQuery,
              where("searchDate", "==", searchDate)
            );
          }
          if (driverNumber) {
            bookingsQuery = query(
              bookingsQuery,
              where("driverNumber", "==", driverNumber)
            );
          }
          if (licensingAuthority) {
            bookingsQuery = query(
              bookingsQuery,
              where(
                "acceptedBy.licensingAuthority",
                "==",
                licensingAuthority.label
              )
            );
          }
          bookingsQuery = query(
            bookingsQuery,
            orderBy("pickupTimestamp", "desc"),
            limit(10)
          );
          //pagination
          if (startAfterDoc) {
            bookingsQuery = query(bookingsQuery, startAfter(startAfterDoc));
          }
          const bookingsSnapshot = await getDocs(bookingsQuery);
          const newBookings = bookingsSnapshot.docs.map((docSnapshot) => {
            //transforming timestamps from non-serializable to milliseconds number
            const data = docSnapshot.data();
            return {
              id: docSnapshot.id,
              ...data,
              createdAt: data.createdAt.toMillis(),
              //convert pickupGeoPoint into serializable object for Redux store
              pickupGeoPoint: {
                latitude: data.pickupGeoPoint.latitude,
                longitude: data.pickupGeoPoint.longitude,
              },
            } as BookingType;
          });
          if (bookingsSnapshot.docs.length > 0) {
            setSearchResult((prevBookings) =>
              prevBookings ? [...prevBookings, ...newBookings] : newBookings
            );
            // Update lastVisible for pagination
            setLastVisible(
              bookingsSnapshot.docs[bookingsSnapshot.docs.length - 1]
            );
          }
          if (bookingsSnapshot.docs.length > 9) {
            setPaginationOn(true);
          } else {
            setPaginationOn(false);
          }
        } catch (error) {
          if (error instanceof Error) {
            setSearchError(error.message);
          } else {
            setSearchError("Error fetching bookings");
          }
        } finally {
          setSearchLoading(false);
          setNextPageLoading(false);
        }
      } else {
        setSearchError("Wrong search parameters");
      }
    },
    [driverNumber, licensingAuthority, searchDate]
  );

  // Infinite scroll logic
  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastBookingRef = useRef<HTMLDivElement | null>(null); // Reference to the last booking element
  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          searchComplete &&
          paginationOn &&
          entries[0].isIntersecting &&
          lastVisible
        ) {
          searchBookings(lastVisible);
        }
      },
      { rootMargin: "200px" }
    );
    observerRef.current = observer; // Assign the observer to the ref
    // Observe the target element only if it exists
    if (lastBookingRef.current) {
      observer.observe(lastBookingRef.current);
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [lastVisible, paginationOn, searchBookings, searchComplete]);

  //form change handlers
  const searchDateChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchDate(event.target.value);
  };
  const driverNumberChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setDriverNumber(event.target.value.replaceAll(" ", ""));
  };

  const submitSearchHandler = () => {
    if (!searchComplete) {
      searchBookings();
      setSearchComplete(true);
    }
  };

  if (userLoading || searchLoading) return <Spinner />;

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/boss">
          <h3>&larr; Admin Menu</h3>
        </Link>
      </BackButtonContainer>
      {userError && <RedHeader>{userError}</RedHeader>}
      {!searchComplete && (
        <>
          <GreenHeader>Search Bookings</GreenHeader>
          {searchError && <RedHeader>{searchError}</RedHeader>}
          <form onSubmit={submitSearchHandler}>
            <GreenP>
              All search fields are optional - You can use any combination of
              parameters
            </GreenP>
            <FormInput
              label="Driver Number"
              id="driver-number"
              type="text"
              onChange={driverNumberChangeHandler}
              value={driverNumber}
            />
            <SpaceAroundArea>
              <GreenP>Date:</GreenP>
              <FormInput
                label=""
                id="search-date"
                type="date"
                onChange={searchDateChangeHandler}
                value={searchDate}
              />
            </SpaceAroundArea>
            <GreenP>Select licensing authority:</GreenP>
            <Select
              placeholder="Licensing Authority..."
              defaultValue={licensingAuthority}
              onChange={setLicensingAuthority}
              options={authorityOptions}
            />
            {searchError && <RedHeader>{searchError}</RedHeader>}
            <BackButtonContainer>
              <Button type="submit">Search Bookings</Button>
            </BackButtonContainer>
          </form>
        </>
      )}

      <>
        {searchComplete && searchResult.length === 0 && !searchError ? (
          <>
            <GreenHeader>
              Sorry, Boss, no results for these parameters...
            </GreenHeader>
            <BackButtonContainer>
              <Button
                onClick={() => {
                  setSearchComplete(false);
                  setDriverNumber("");
                  setSearchDate("");
                  setLicensingAuthority(null);
                  setSearchResult([]);
                }}
              >
                Search Again
              </Button>
            </BackButtonContainer>
          </>
        ) : searchResult.length > 0 && !searchError ? (
          <>
            {searchResult.length > 3 && (
              <BackButtonContainer>
                <Button
                  onClick={() => {
                    setSearchComplete(false);
                    setDriverNumber("");
                    setSearchDate("");
                    setLicensingAuthority(null);
                    setSearchResult([]);
                  }}
                >
                  Search Again
                </Button>
              </BackButtonContainer>
            )}
            <GreenHeader>Bookings for Your search parameters:</GreenHeader>
            {searchResult.map((booking, index) => {
              return (
                <div
                  key={booking.id}
                  ref={
                    index === searchResult.length - 1 ? lastBookingRef : null
                  } // Assign ref conditionally for observer to fetch more users
                >
                  <InnerCard
                    onClick={() =>
                      navigate(`/boss/booking-details/${booking.id}`)
                    }
                  >
                    <PushToLeftColumn>
                      <h3>
                        {booking.date} {booking.time}
                      </h3>
                      <p>Pickup: {booking.pickupAddress.googleAddress}</p>
                      {booking.driverNumber ? (
                        <GreenP>
                          Driver: <strong>{booking.driverNumber}</strong>
                        </GreenP>
                      ) : (
                        <AmberP>No Driver</AmberP>
                      )}
                    </PushToLeftColumn>
                    <PushToRightColumn>
                      <BookingStatus booking={booking} />
                    </PushToRightColumn>
                  </InnerCard>
                </div>
              );
            })}
            {nextPageLoading && <Spinner />}
            <BackButtonContainer>
              <Button
                onClick={() => {
                  setSearchComplete(false);
                  setDriverNumber("");
                  setSearchDate("");
                  setLicensingAuthority(null);
                  setSearchResult([]);
                }}
              >
                Search Again
              </Button>
            </BackButtonContainer>
          </>
        ) : (
          searchError && <RedHeader>{searchError}</RedHeader>
        )}
      </>
    </Card>
  );
};

export default SearchBookings;
