import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import Card from "../UI/card/card.component";
import Spinner from "../UI/spinner/spinner.component";
import ErrorMessage from "../UI/error-message/error-message.component";
import { GreenHeader, GreenP } from "../../global.styles";
import InnerCard from "../UI/inner-card/inner-card.component";

const PaperworkListForReview = () => {
  const navigate = useNavigate();

  const { userListLoading, userListError, userList } = useSelector(
    (state: RootState) => state.userList
  );

  const navigateToDetailsHandler = (userId: string) => {
    navigate(`/boss/user-details/${userId}`);
  };

  return (
    <Card>
      {userListLoading && <Spinner />}
      {userListError && (
        <ErrorMessage slice="userList">{userListError}</ErrorMessage>
      )}
      <GreenHeader>Drivers patiently waiting to be approved:</GreenHeader>
      {userList && userList.length === 0 && (
        <GreenP>No users waiting to be approved</GreenP>
      )}
      {userList &&
        userList.length > 0 &&
        userList.map((user) => (
          <InnerCard
            key={user.id}
            onClick={navigateToDetailsHandler.bind(null, user.id)}
          >
            <h3>{user.name}</h3>
            <p>{user.driversData?.licensingAuthority}</p>
          </InnerCard>
        ))}
    </Card>
  );
};

export default PaperworkListForReview;
