import { FC } from "react";

import { BookingType } from "../../../redux/bookings/bookings.types";
import { defineBookingStatus } from "../../../util-functions";
import { VerticalArea } from "../../../global.styles";

type BookingStatusProps = {
  booking: BookingType;
};

const BookingStatusheader: FC<BookingStatusProps> = ({ booking }) => {
  const statusMessage = defineBookingStatus(booking).message;
  const statusColor = defineBookingStatus(booking).color;
  return (
    <VerticalArea>
      <h3 style={{ color: statusColor }}>{statusMessage}</h3>
    </VerticalArea>
  );
};

export default BookingStatusheader;
