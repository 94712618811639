import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaDog, FaExclamationTriangle } from "react-icons/fa";

import {
  BackButtonContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  SpreadButtonContainer,
  TextDivider,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import {
  BookingType,
  isBookingType,
  PublicSearchParamsType,
} from "../../../redux/bookings/bookings.types";
import Button from "../../UI/button/button.component";
import Addresses from "../../bookings/addresses.component";
import { defineVehicleType } from "../../../util-functions";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import { acceptJobRequest } from "../../../firebase/user";
import { useState } from "react";
import { colors } from "../../../style-variables";

const PublicJobDetails = () => {
  const navigate = useNavigate();

  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const [acceptJobLoading, setAcceptJobLoading] = useState(false);
  const [acceptJobError, setAcceptJobError] = useState("");
  const [jobAccepted, setJobAccepted] = useState(false);

  //get state that was passed to this route
  const { state } = useLocation();

  if (!state || !approvedDriver) {
    return (
      <Card>
        <RedHeader>Wrong Parameters...</RedHeader>
        <Button onClick={() => navigate(-1)}>Try Again</Button>
      </Card>
    );
  }

  const job = state.job as BookingType;
  let searchParams: PublicSearchParamsType | null;
  let goBackLink: string;
  if (state.searchParams) {
    searchParams = state.searchParams as PublicSearchParamsType;
    goBackLink = `/drivers/public-job-search?driversHash=${searchParams.driversHash}&area=${searchParams.area}&date=${searchParams.date}`;
  } else {
    goBackLink = "/drivers/public-job-list";
  }

  if (!isBookingType(job)) {
    return (
      <Card>
        <RedHeader>Wrong Parameters...</RedHeader>
        <Button onClick={() => navigate(-1)}>Try Again</Button>
      </Card>
    );
  }

  const acceptJobHandler = () => {
    if (!!job.id) {
      setAcceptJobLoading(true);
      acceptJobRequest(job.id)
        .then((accepted) => {
          setJobAccepted(accepted);
          setAcceptJobLoading(false);
        })
        .catch((error) => {
          if (error instanceof Error) {
            setAcceptJobError(error.message);
          } else {
            setAcceptJobError(
              "Problem accepting this job... Please make sure all parameters match. If problem still exists, please let us know"
            );
          }
          setAcceptJobLoading(false);
        });
    } else {
      navigate("/drivers/driver-panel");
    }
  };

  return (
    <Card>
      {(approvedDriverLoading || acceptJobLoading) && <Spinner />}
      {approvedDriverError && <RedHeader>{approvedDriverError}</RedHeader>}
      {acceptJobError && !acceptJobLoading && (
        <RedHeader>{acceptJobError}</RedHeader>
      )}
      {jobAccepted && !acceptJobLoading && (
        <>
          <GreenHeader>Job successfully accepted!</GreenHeader>
          <BackButtonContainer>
            <Button
              buttonType="green"
              onClick={() => navigate("/drivers/accepted-jobs")}
            >
              OK
            </Button>
          </BackButtonContainer>
        </>
      )}
      {!jobAccepted && !acceptJobLoading && (
        <>
          <BackButtonContainer>
            <ClickableArea onClick={() => navigate(goBackLink)}>
              <h3>Go Back</h3>
            </ClickableArea>
          </BackButtonContainer>
          <GreenHeader>
            Public request for
            <br />
            {job.date}
            <br />
            at {job.time}
          </GreenHeader>
          <TextDivider>
            <GreenP> ADDRESSES </GreenP>
          </TextDivider>
          <Addresses booking={job} />
          <TextDivider>
            <GreenP> DETAILS </GreenP>
          </TextDivider>
          <GreenP>Passenger Name</GreenP>
          <p>{job.passengerName}</p>
          <br />
          <GreenP>Passenger Phone Number</GreenP>
          <p>{job.passengerPhone}</p>
          <br />
          <GreenP>Vehicle Type:</GreenP>
          <p>{defineVehicleType(job.vehicleType)}</p>
          {job.hasPet && (
            <>
              <br />
              <GreenP>
                <FaDog /> Booking includes pet.
              </GreenP>
            </>
          )}
          {job.driverNote && (
            <>
              <br />
              <GreenP>Note to the Driver</GreenP>
              <p>{job.driverNote}</p>
            </>
          )}
          <TextDivider>
            <GreenP> PRICE </GreenP>
          </TextDivider>
          <GreenHeader> £{job.quotedPrice}*</GreenHeader>
          <GreenP>
            * Amount to pay to the Driver. Any parking and/or toll fees and/or
            waitng time charges to be added. Please see{" "}
            <Link to="/terms">Terms and Conditions</Link>
          </GreenP>
          <TextDivider>
            <GreenP> ACTIONS </GreenP>
          </TextDivider>
          {acceptJobError ? (
            <RedHeader>{acceptJobError}</RedHeader>
          ) : (
            <>
              <FaExclamationTriangle size={24} color={colors.primary} />
              <GreenP>
                <b>Important Reminder:</b>
              </GreenP>
              <GreenP>
                Before accepting this job, please check your other bookings to
                ensure there are no scheduling conflicts. Let’s keep all jobs on
                time! Thank you!
              </GreenP>
            </>
          )}
          <SpreadButtonContainer>
            <Button buttonType="inverted" onClick={() => navigate(goBackLink)}>
              {state.searchParams ? "Back To Results" : "Back to Job List"}
            </Button>
            <Button onClick={acceptJobHandler}>Accept The Job</Button>
          </SpreadButtonContainer>
        </>
      )}
    </Card>
  );
};

export default PublicJobDetails;
